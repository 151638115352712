import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginComponent} from './pages/login/login.component';
import {DesignTestsComponent} from './pages/design-tests/design-tests.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatNativeDateModule} from '@angular/material/core';
import {MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER} from '@angular/material/datepicker';
import {MAT_SELECT_SCROLL_STRATEGY_PROVIDER} from '@angular/material/select';
import {MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER} from '@angular/material/autocomplete';
import {MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER} from '@angular/material/tooltip';
import {SidenavService} from './app-layout/service/sidenav.service';
import {MAT_CHIPS_DEFAULT_OPTIONS} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {ToolbarService} from './app-layout/service/toolbar.service';
import {AppLayoutModule} from './app-layout/app-layout.module';
import {ApiInterceptor} from './infrastructure/interceptor/api-interceptor';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppFormsModule} from './app-layout/components/forms/app-forms.module';
import { UpdateApplicationService } from './update-application.service';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import {ComponentsModule} from './app-layout/components/components.module';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {PlanComponent} from './pages/plan/plan.component';

registerLocaleData(localePt);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PlanComponent,
    DesignTestsComponent
  ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        RouterModule,
        AppLayoutModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        TranslateModule.forRoot({
            defaultLanguage: 'pt-BR',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AppFormsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production, registrationStrategy: 'registerWhenStable:30000'}),
        ComponentsModule
    ],
  providers: [
    MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER,
    MAT_SELECT_SCROLL_STRATEGY_PROVIDER,
    MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
    MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER,
    SidenavService,
    ToolbarService,
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [ENTER, COMMA]
      }
    },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    UpdateApplicationService
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
