import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './design-tests.component.html',
  styleUrls: ['./design-tests.component.scss']
})
export class DesignTestsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
