<div [className]="getFormGroupClass()" [formGroup]="formGroup">
  <label class="form-control-label mb-0" [for]="dbname" *ngIf="name">{{name}}</label>
  <input type="search" [formControlName]="dbname"
         [className]="getFormControlClass()"
         [matAutocomplete]="auto"
         [placeholder]="description"
         [required]="required"
         [readonly]="readonly"
         [disabled]="disabled"
         (input)="keydownInput($event)"
         (change)="searchChange($event)">
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getDescription" (optionSelected)="changeInput($event)">
    <mat-option *ngFor="let option of filteredOptions" [value]="option">
      {{option.name}}
    </mat-option>
    <mat-option value="" *ngIf="addNewOption && filteredOptions.length === 0 && this.lastText.trim() !== ''"><h6 class="heading-small text-muted m-0"><i class="fas fa-plus-circle"></i> {{ 'core.auto_complete.add_new' | translate }}</h6></mat-option>
  </mat-autocomplete>
</div>
