import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseFieldComponent, FieldType} from '../base-field/base-field.component';

@Component({
  selector: 'app-input-numeric',
  templateUrl: './input-numeric.component.html',
  styleUrls: ['./input-numeric.component.scss']
})
export class InputNumericComponent extends BaseFieldComponent implements OnChanges {

  @Input()
  decimal = 2;

  @Input()
  min: number | null = null;

  @Input()
  max: number | null = null;

  constructor() {
    super();
    this.fieldType = FieldType.NUMBER;
  }

  ngOnInit(): void {
    this.formGroup.addControl(this.dbname, this.formControl);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.data && this.formControl.value !== this.data) {
      this.formControl.setValue(parseFloat(this.data).toFixed(this.decimal));
    }
  }

  public changeInput(event: any): void {
    if (event.target.value) {
      this.formControl.setValue(parseFloat(event.target.value).toFixed(this.decimal));
      super.changeInput(event);
    }
  }

}
