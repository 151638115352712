import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudField} from '../domain/crud-generic-object';
import {ApiService} from '../../../infrastructure/service/api.service';

@Injectable({
  providedIn: 'root'
})
export class CrudService extends ApiService {

  private serviceUrl = 'crud/{window}';

  public findByFilters(window: string, filters: CrudField[]): Promise<any> {
    const methodUrl = this.serviceUrl + '/find-by-filters';

    return super.post(methodUrl.replace('{window}', window), filters).toPromise();
  }

  public findById(window: string, id: string): Promise<any> {
    const methodUrl = this.serviceUrl + '/{id}';
    return super.get(methodUrl.replace('{window}', window).replace('{id}', id)).toPromise();
  }

  public save(window: string, id: string, fields: CrudField[]): Promise<any> {
    if (id) {
      const methodUrl = this.serviceUrl + '/{id}';
      return super.patch(methodUrl.replace('{window}', window).replace('{id}', id), fields).toPromise();
    } else {
      const methodUrl = this.serviceUrl;
      return super.post(methodUrl.replace('{window}', window), fields).toPromise();
    }
  }

  public inactivate(window: string, id: string): Promise<any> {
    const methodUrl = this.serviceUrl + '/{id}';
    return super.delete(methodUrl.replace('{window}', window).replace('{id}', id)).toPromise();
  }

  public reactivate(window: string, id: string): Promise<any> {
    const methodUrl = this.serviceUrl + '/{id}/reactivate';
    return super.patch(methodUrl.replace('{window}', window).replace('{id}', id)).toPromise();
  }
}
