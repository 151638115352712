import * as uuid from 'uuid';
import {Injectable} from '@angular/core';
import {QueueAutoSave, QueueAutoSaveFields, QueueAutoSaveOperation, QueueAutoSaveStatus} from '../domain/queue-auto-save';
import {ApiService} from '../../../infrastructure/service/api.service';
import {CrudField} from '../../crud/domain/crud-generic-object';
import {SnackbarService} from '../../../infrastructure/service/snackbar.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

export const QUEUE_DRAFT = 'queue_draft';
export const QUEUE_SAVE = 'queue_save';
export const QUEUE_SAVED = 'queue_saved';
export const QUEUE_ERROR = 'queue_error';

@Injectable({
  providedIn: 'root'
})
export class AutoSaveService extends ApiService {

  constructor(private snackBar: SnackbarService, http: HttpClient) {
    super(http);
  }

  private getQueueOfWindow(window: string): QueueAutoSave[] | undefined {
    const queueAutoSave: any = JSON.parse(localStorage.getItem(QUEUE_SAVE) || '{}');
    if (!queueAutoSave) {
      return undefined;
    }

    const queueWindow: QueueAutoSave[] = queueAutoSave[window];
    if (!queueWindow) {
      return undefined;
    }

    return queueWindow;
  }

  private isValid(record: QueueAutoSave): boolean {
    return record.fields?.every(f => !f.invalid) || false;
  }

  private saveQueueOfWindow(window: string, queue: QueueAutoSave[]): void {
    const queueAutoSave: any = JSON.parse(localStorage.getItem(QUEUE_SAVE) || '{}');
    queueAutoSave[window] = queue;

    localStorage.setItem(QUEUE_SAVE, JSON.stringify(queueAutoSave));
  }

  private findDraftRecordIndex(window: string): number | undefined {
    return this.getQueueOfWindow(window)?.findIndex(q => q.operation === QueueAutoSaveOperation.POST &&
      q.status === QueueAutoSaveStatus.PENDING);
  }

  private findRecordIndex(window: string, id?: string): number | undefined {
    return this.getQueueOfWindow(window)?.findIndex(q => q.id === id &&
      q.status === QueueAutoSaveStatus.PENDING) || this.findDraftRecordIndex(window);
  }

  private newRecord(id: string | undefined, window: string, operation: QueueAutoSaveOperation,
                    fields: QueueAutoSaveFields[], parentId?: string): QueueAutoSave {
    const record: QueueAutoSave = {
      id,
      timestamp: new Date().getTime(),
      status: QueueAutoSaveStatus.PENDING,
      operation,
      fields,
      parentId
    };

    return record;
  }

  private newDraftRecord(window: string, fields: QueueAutoSaveFields[], parentId?: string): QueueAutoSave {
    return this.newRecord(undefined, window, QueueAutoSaveOperation.POST, fields, parentId);
  }

  private saveDraft(window: string, record: QueueAutoSave): void {
    const queueDraft: any = JSON.parse(localStorage.getItem(QUEUE_DRAFT) || '{}');
    queueDraft[window] = record;
    localStorage.setItem(QUEUE_DRAFT, JSON.stringify(queueDraft));
  }

  private deleteDraft(window: string): void {
    const queueDraft: any = JSON.parse(localStorage.getItem(QUEUE_DRAFT) || '{}');
    queueDraft[window] = undefined;
    localStorage.setItem(QUEUE_DRAFT, JSON.stringify(queueDraft));
  }


  private sendToBackend(serviceUrl: string, q: QueueAutoSave): Observable<any> | undefined{
    switch (q.operation) {
      case QueueAutoSaveOperation.POST:
        return this.post(serviceUrl, this.toCrudField(q.fields));
        break;
      case QueueAutoSaveOperation.PATCH:
        return this.patch(serviceUrl, this.toCrudField(q.fields));
        break;
      // case QueueAutoSaveOperation.DELETE:
      //   return new Promise<any>().
      //   break;
      default:
        return;
    }
  }

  private toCrudField(fields: QueueAutoSaveFields[] | undefined): CrudField[] {
    const crudFields: CrudField[] = [];
    fields?.forEach(f => {
      crudFields.push({
        name: f.name,
        value: f.value
      });
    });
    return crudFields;
  }

  // FIXME: Verificar problemas de concorrencia, ja que se durante o processo adicionar um novo registro a salvar iremos perder os dados
  public queue(window: string, autoSaveFields: QueueAutoSaveFields[] | QueueAutoSaveFields, parentId?: string, id?: string): Promise<any> | undefined {

    const fields: QueueAutoSaveFields[] = Array.isArray(autoSaveFields) ? autoSaveFields : [autoSaveFields];

    const queueOfWindow = this.getQueueOfWindow(window) || [];
    const recordIndex: number | undefined = this.findRecordIndex(window, id);

    // FIXME: Retirado gravacao de draft por enquanto
    // const record: QueueAutoSave = this.findDraftRecord(window, id)
    //   || (id ? this.newRecord(id, window, QueueAutoSaveOperation.PATCH, fields, parentId)
    //     : this.newDraftRecord(window, fields, parentId));

    const record: QueueAutoSave = (id
      ? this.newRecord(id, window, QueueAutoSaveOperation.PATCH, fields, parentId)
      : this.newDraftRecord(window, fields, parentId));

    const newFieldsList: QueueAutoSaveFields[] = fields;
    record.fields?.forEach(f => {
      if (!newFieldsList.find(nf => nf.name === f.name)) {
        newFieldsList.push(f);
      }
    });
    record.fields = newFieldsList;

   const index = record.fields.findIndex(field => field.name === "active" && field.value === "false");
   if(index !== -1){
    return;
   }


    if (this.isValid(record)) {
      return new Promise<any>((resolve, reject) => {
        this.saveQueue(window, record)
          .then((r) => {
            if (record.operation === QueueAutoSaveOperation.POST) {
              this.snackBar.success('Record saved with success');
            } else if (record.operation === QueueAutoSaveOperation.DELETE) {
              this.snackBar.success('Record deleted with success');
            }

            this.deleteDraft(window);
            resolve(r);
          })
          .catch((reason) => {
            this.snackBar.error('Error: ' + (reason?.error?.error || reason?.error));
            this.saveDraft(window, record);

            if (recordIndex && recordIndex >= 0) {
              queueOfWindow[recordIndex] = record;
            } else {
              queueOfWindow.push(record);
            }

            // this.saveQueueOfWindow(window, queueOfWindow);
            // this.deleteDraft(window);
            reject(reason);
          });
      });
    } else {
      this.saveDraft(window, record);
      return undefined;
    }
  }

  public findDraftRecord(window: string, id?: string): QueueAutoSave | undefined {
    const queueOfWindow = this.getQueueOfWindow(window) || [];
    const recordIndex: number | undefined = this.findRecordIndex(window, id);
    let record: QueueAutoSave | undefined = recordIndex && recordIndex >= 0 ? queueOfWindow[recordIndex] : undefined;

    if (!id) {
      const queueDraft: any = JSON.parse(localStorage.getItem(QUEUE_DRAFT) || '{}');
      const recordDraft: QueueAutoSave = queueDraft[window];
      if (recordDraft) {
        record = record || recordDraft;
        const newFieldsList: QueueAutoSaveFields[] = recordDraft.fields || [];
        record.fields?.forEach(f => {
          if (!newFieldsList.find(nf => nf.name === f.name)) {
            newFieldsList.push(f);
          }
        });
        record.fields = newFieldsList;
      }
    }

    return record;
  }

  // TODO: Esta função deve trabalhar de forma asyncrona para salvar registros pendentes devido a erros de conexão
  public syncronize(): void {
    const queueAutoSave: any = JSON.parse(localStorage.getItem(QUEUE_SAVE) || '{}');

    const keys = Object.keys(queueAutoSave);
    if (keys.length > 0) {
      keys.forEach((key) => {
        const queueOfWindow: QueueAutoSave[] = queueAutoSave[key];
        queueOfWindow.forEach(q => {
          this.saveQueue(key, q)
            .then(() => console.log('Success'))
            .catch(() => console.log('Error in autosave', key, q));
        });

      });

      localStorage.setItem(QUEUE_SAVE, JSON.stringify({}));
    }
  }

  private saveQueue(key: string, q: QueueAutoSave): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const serviceUrl = `${key}/${q.id || ''}`;
      this.sendToBackend(serviceUrl, q)?.subscribe((r) => {
        // Removido para evitar encher o navegador do usuário
        // const queueSaved: any = JSON.parse(localStorage.getItem(QUEUE_SAVED) || '{}');
        // queueSaved[key] = queueSaved[key] || [];
        // queueSaved[key].push(q);
        // localStorage.setItem(QUEUE_SAVED, JSON.stringify(queueSaved));
        resolve(r);
      }, error => {
        // FIXME: Adicionar tratamento de erros
        // Quando o erro for de conexão, voltar para a fila para tentar no próximo loop e avisar usuario com WARN
        // Quando o erro for outro, jogar para fila de erros para analise e avisar o usuario com ERROR
        const queueError: any = JSON.parse(localStorage.getItem(QUEUE_ERROR) || '{}');
        queueError[key] = queueError[key] || [];
        queueError[key].push(q);
        localStorage.setItem(QUEUE_ERROR, JSON.stringify(queueError));
        reject(error);
      });
    });
  }

  public onExit(window: string, id?: string, parentId?: string, draftFields?: QueueAutoSaveFields[]): boolean {
    if (id) {
      const recordIndex: number | undefined = this.findRecordIndex(window, id);
      if (recordIndex && recordIndex < 0) {
        this.snackBar.warning('Required fields not filled, record is not saved');
      }
    } else {
      if (draftFields) {
        const record: QueueAutoSave = this.newDraftRecord(window, draftFields, parentId);
        this.saveDraft(window, record);

        this.snackBar.info('Draft saved');
      }
    }
    return true;
  }

  public toQueueAutoSaveFieldsIgnoreNull(object: any): QueueAutoSaveFields[] {
    return this.toQueueAutoSaveFields(object).filter(f => f.value);
  }

  public toQueueAutoSaveFields(object: any): QueueAutoSaveFields[] {
    const fields: QueueAutoSaveFields[] = [];

    if (!Array.isArray(object)) {
      // if (object instanceof QueueAutoSaveFields) {
      //   return [object];
      // }
      Object.keys(object).forEach(k => fields.push({name: k, value: object[k], invalid: false}));
    } else {
      object.forEach((o: any) => {
        // if (o instanceof QueueAutoSaveFields) {
        //   fields.push(o);
        // } else {
          Object.keys(o)
            .forEach(k => fields.push({name: k, value: o[k], invalid: false}));
        // }
      });
    }

    return fields;
  }
}
