import {Injectable} from '@angular/core';

@Injectable()
export class ToolbarService {

  protected context: any;

  protected title = '';

  protected toolbar: any[] = [];

  public getTitle(): string {
    return this.title;
  }

  public getToolbar(): any[] {
    return this.toolbar;
  }

  public executeCallback(callback: any, params: any): void {
    callback(this.context, params);
  }

  public create(context: any, title: string, toolbar: any[]): void {
    this.context = context;
    this.title = title;
    this.toolbar = toolbar;
  }

  public destroy(): void {
    this.context = undefined;
    this.title = 'LEGALMANAGER LITE';
    this.toolbar = [];
  }

}
