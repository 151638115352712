import { Injectable } from '@angular/core';
import {Card} from '../../modules/card/domain/card';
import {CardComponent} from '../../modules/card/components/card.component';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  public cards: CardComponent[] = [];

  constructor() { }

  public removeCard(card: CardComponent): void {
    this.cards = this.cards.filter(c => c !== card);
  }

  public addCard(card: CardComponent): void {
    this.cards.push(card);
  }
}
