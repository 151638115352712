import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Card, CardType} from '../domain/card';
import {CardService} from '../service/card.service';
import {ParamMap} from '@angular/router';
import {Field} from '../../crud/domain/window';
import {CoreService} from '../../../app-layout/service/core.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit, OnDestroy, OnChanges {

  cardType: typeof CardType = CardType;

  @Input()
  showHeader = true;

  @Input()
  id!: string;

  @Input()
  card!: Card;

  @Input()
  cardStyle = 'default';

  @Input()
  params!: ParamMap;

  @Input()
  field!: Field;

  @Input()
  data!: any;

  response: any = {};

  @Input()
  darkCard = false;

  constructor(private cardService: CardService, private coreService: CoreService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      if (this.field?.whereClause) {
        let reload = false;
        Object.keys(changes).forEach(k => {
          if (this.field?.whereClause?.toLowerCase().includes(':' + k)) {
            reload = true;
          }
        });
        if (reload) {
          this.draw();
        }
      }
    }
  }

  ngOnInit(): void {
    if (this.id) {
      this.cardService.findById(this.id).then(c => {
        this.card = c;
        setTimeout(() => this.draw());
      });
    } else {
      setTimeout(() => this.draw());
    }
    this.coreService.addCard(this);
  }

  ngOnDestroy(): void {
    this.coreService.removeCard(this);
  }

  public draw(params?: any): void {
    if (this.field?.whereClause && !this.data) {
      return;
    }

    this.cardService.draw(this.card.id, this.getRequestBody(params)).then((r) => {
      const cardCanvas = document.getElementById('card-' + this.card.id);
      this.response = r;
      if (this.card.type === CardType.HTML && r.js) {
        // tslint:disable-next-line:no-eval
        eval(r.js);
      }
    });
  }

  private getRequestBody(params?: any): any {
    const paramsObj: any[] = [];
    paramsObj.push({name: 'fieldId', value: this.field?.id});
    if (params) {
      Object.keys(params).forEach(k => {
        paramsObj.push({name: k, value: params[k]});
      });
    }

    if (this.params) {
      this.params.keys.forEach(k => {
        paramsObj.push({name: k, value: this.params.get(k)});
      });
    }
    if (this.field?.whereClause && this.field?.whereClause !== '' && this.data) {
      Object.keys(this.data).forEach(k => {
        paramsObj.push({name: k, value: this.data[k]});
      });
    }

    return {
      recordId: [this.data?.id],
      params: paramsObj
    };
  }
}
