<div class="bg-secondary shadow p-0 m-0 modal-container">
  <div mat-dialog-title>
    <div class="row">
      <h1 class="col-6">{{this.windowComponent?.window?.name}}</h1>
      <div class="col-6 text-right">
        <label class="custom-toggle mr-3">
          <input id="active" type="checkbox" (click)="activateOrInactivate()" [checked]="getActive()">
          <span matTooltip="Activate/Inactivate" class="custom-toggle-slider rounded-circle"></span>
        </label>
      </div>
    </div>
  </div>
  <div mat-dialog-content>
    <app-window [id]="id" [parentId]="parentId" [windowPath]="windowPath" [path]="path" [params]="params"></app-window>
  </div>
  <div mat-dialog-actions>
    <div class="col-12 text-right">
      <button class="btn btn-outline-secondary" [mat-dialog-close]="true">Close</button>
    </div>
  </div>
</div>
