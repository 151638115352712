import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {WindowComponent} from '../window/window.component';
import {convertToParamMap, ParamMap} from '@angular/router';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  id!: string;

  parentId!: string;

  windowPath!: string;

  path!: string;

  params!: ParamMap;

  @ViewChild(WindowComponent)
  windowComponent!: WindowComponent;

  constructor(public dialogRef: MatDialogRef<ModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.id = 'new' !== this.data.id ? this.data.id : undefined;
    this.parentId = this.data.parentId;
    this.windowPath = this.data.windowPath;
    this.path = this.data.path;
    this.params = convertToParamMap(this.data.params);
  }

  getActive(): boolean {
    return this.windowComponent?.data?.active !== 'false';
  }

  activateOrInactivate(): void {
    this.windowComponent?.activateOrInactivate();
  }
}
