<app-core></app-core>
<div
  class="app-layout-container"
  [class.example-is-mobile]="mobileQuery.matches"
>
  <mat-sidenav-container
    class="app-layout-sidenav-container bg-white"
    [style.marginTop.px]="0"
  >
    <mat-sidenav
      #sidenav
      mode="over"
      position="end"
      [fixedInViewport]="true"
      [fixedTopGap]="0"
      [fixedBottomGap]="0"
    >
      <router-outlet name="sidenav"></router-outlet>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="main-contact-content m-0">
        <!-- Pages -->
        <router-outlet name="main"></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
