import {Component, Input, OnInit} from '@angular/core';
import {Card} from '../../domain/card';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @Input()
  card!: Card;

  @Input()
  data!: any;

  constructor() { }

  ngOnInit(): void {
  }

}
