<span [ngSwitch]="field.type">
  <app-card *ngSwitchCase="crudFieldType.CARD" [field]="field" [data]="data" [card]="getCard(field)" [params]="params" [showHeader]="showHeader"></app-card>
  <button *ngSwitchCase="crudFieldType.BUTTON" (click)="onExecuteAction(field?.action?.id)" class="btn btn-sm btn-primary">{{field.name}}</button>
  <span *ngSwitchDefault [ngSwitch]="field.columnType">
    <app-input-date *ngSwitchCase="crudColumnType.DATE" (changed)="onChangeInput($event)" [data]="getFieldData(field.dbname)" [formGroup]="formGroup" [id]="field.id" [dbname]="field.dbname" [name]="field.name" [description]="field.description" [required]="field.mandatory"></app-input-date>
    <app-table *ngSwitchCase="crudColumnType.TABLE" (changed)="onChangeInput($event)" [data]="getFieldData(field.dbname)" [formGroup]="formGroup" [id]="field.id" [dbname]="field.dbname" [name]="field.name" [description]="field.description" [mandatory]="field.mandatory" [formData]="data" [field]="field" [params]="params"></app-table>
    <app-input-numeric *ngSwitchCase="crudColumnType.NUMBER" (changed)="onChangeInput($event)" [data]="getFieldData(field.dbname)" [formGroup]="formGroup" [id]="field.id" [dbname]="field.dbname" [name]="field.name" [description]="field.description" [required]="field.mandatory"></app-input-numeric>
    <app-text-label *ngSwitchCase="crudColumnType.LABEL" [data]="getFieldData(field.dbname)" [formGroup]="formGroup" [id]="field.id" [dbname]="field.dbname" [name]="field.name" [description]="field.description"></app-text-label>
    <app-input-text *ngSwitchDefault (changed)="onChangeInput($event)" [data]="getFieldData(field.dbname)" [formGroup]="formGroup" [id]="field.id" [dbname]="field.dbname" [name]="field.name" [description]="field.description" [required]="field.mandatory"></app-input-text>
  </span>
</span>
