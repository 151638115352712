import {Injectable} from '@angular/core';
import {ApiService} from '../../infrastructure/service/api.service';
import {AppNotification} from '../../modules/core/notification/domain/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends ApiService {

  private serviceUrl = 'notifications';

  public findAll(): Promise<AppNotification[]> {
    return this.get(this.serviceUrl).toPromise();
  }

  markAsRead(id: string): Promise<any> {
    const methodUrl = `${this.serviceUrl}/${id}/read`;
    return this.post(methodUrl).toPromise();
  }

  markAllAsRead(): Promise<any> {
    const methodUrl = `${this.serviceUrl}/read-all`;
    return this.post(methodUrl).toPromise();
  }
}
