import {Component, Input, OnInit} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Component({
  selector: 'app-doughnut',
  templateUrl: './doughnut.component.html',
  styleUrls: ['./doughnut.component.scss']
})
export class DoughnutComponent implements OnInit {

  @Input()
  percent: number[] = [0];

  @Input()
  text: string | undefined;

  @Input()
  description = '';

  @Input()
  color: string[] = [];

  constructor(private decimalPipe: DecimalPipe) { }

  ngOnInit(): void {
  }

  getSegmentClass(segmentNumber: number): string {
    if (this.color.length > 0) {
      return `donut-segment donut-segment-${this.color[segmentNumber]}`;
    }
    if (this.percent[segmentNumber] === 0) {
      return 'donut-segment donut-segment-secondary';
    } else if (this.percent[segmentNumber] < 100) {
      return 'donut-segment donut-segment-info';
    } else {
      return 'donut-segment donut-segment-success';
    }
  }

  getTextClass(): string {
    if (this.color.length > 0) {
      return `donut-percent donut-text-${this.color[0]}`;
    } else {
      if (this.percent[0] === 0) {
        return `donut-percent donut-text-secondary`;
      } else if (this.percent[0] < 100) {
        return `donut-percent donut-text-info`;
      } else {
        return `donut-percent donut-text-success`;
      }
    }
  }

  getText(): string {
    if (this.text) {
      return this.text;
    }
    return this.decimalPipe.transform(this.percent[0], '1.0-0') + '%';
  }
}
