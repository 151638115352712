import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Field} from '../../../../../../modules/crud/domain/window';
import {FieldOptionData} from '../../../../../../modules/crud/domain/field-option-data';
import {TableService} from '../../../../../../modules/crud/service/table.service';
import {ParamMap} from '@angular/router';
import {FormGroup} from '@angular/forms';
import {FormField} from '../../../../forms/base-field/base-field.component';
import {CrudColumnType} from '../../../../../../modules/crud/domain/crud-column-type';
import {AutoCompleteOption} from '../../../../forms/auto-complete/auto-complete-option';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {

  @Input()
  formGroup: FormGroup = new FormGroup({});

  @Input()
  data!: any;

  @Input()
  id!: string;

  @Input()
  name!: string;

  @Input()
  description!: string;

  @Input()
  dbname!: string;

  @Input()
  readonly = false;

  @Input()
  disabled = false;

  @Input()
  mandatory = false;

  @Input()
  whereClause!: string;

  @Input()
  serverSide = false;

  @Output()
  changed = new EventEmitter<FormField>();

  @Input()
  params!: ParamMap;

  @Input()
  field!: Field;

  @Input()
  formData: any;

  sourceData!: AutoCompleteOption;
  options: FieldOptionData[] = [];

  constructor(private service: TableService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.data) {
      this.service.findById(this.field.columnTableId, this.data).then(d => {
        this.data = d;
      });
    }
  }

  ngOnInit(): void {
    this.findOptions();
  }

  public onChangeInput(event: any): void {
    this.changed.emit(event);
  }

  public onFilterChange(event: any): void {
    if (this.serverSide) {
      this.findOptions();
    }
  }

  // FIXME: Trabalhar com paginação também no server quando tiver paginacao de mais de X registros
   findOptions(): void {
    if (this.whereClause && !this.data) {
      return;
    }

    this.service.findByDisplayText(this.field.columnTableId, undefined, this.getRequestBody()).then(d => {
      this.options = d;
    });
  }

  private getRequestBody(): any {
    const paramsObj: any[] = [];
    paramsObj.push({name: 'fieldId', value: this.field?.id});
    if (this.params) {
      this.params.keys.forEach(k => {
        paramsObj.push({name: k, value: this.params.get(k)});
      });
    }
    if (this.field?.whereClause && this.field?.whereClause !== '' && this.formData) {
      Object.keys(this.formData).forEach(k => {
        paramsObj.push({name: k, value: this.formData[k]});
      });
    }
    return {
      recordId: [this.formData?.id],
      params: paramsObj
    };
  }

}
