import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {BaseFieldComponent, FieldType} from '../base-field/base-field.component';
import {MatDatepicker} from '@angular/material/datepicker';
import {Moment} from 'moment/moment';
import * as moment from 'moment/moment';
import {DateConverter} from '../../../../modules/core/converter/date-converter';

export const PICKER_DEFAULT_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss']
})
export class InputDateComponent extends BaseFieldComponent implements OnInit, OnChanges {

  @Input()
  customFormat!: string;

  @Input()
  closeOnClick: 'day' | 'month' | 'year' = 'day';

  @Input()
  startView: 'month' | 'year' | 'multi-year' = 'month';

  @ViewChild('picker')
  picker!: MatDatepicker<any>;

  constructor() {
    super();
    this.fieldType = FieldType.DATE;
  }

  public changeInput(event: any): void {
    this.emitChange(event.target.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.formControl.value !== this.data) {
      this.formControl.setValue(DateConverter.toDate(this.data));
    }
  }

  verifyClose(normalizedMonthAndYear: any, selected: string, datepicker: MatDatepicker<Moment>): void {
    if (this.closeOnClick === 'day') {
      return;
    }

    if (this.closeOnClick === selected) {
      const value: Date = moment(normalizedMonthAndYear).toDate();
      this.formControl.setValue(value);
      this.emitChange(value);
      datepicker.close();
    }
  }

  skipChanges(): boolean {
    return false;
  }

  private emitChange(value: Date): void {
    this.changed.emit({id: this.id, name: this.dbname, value: value
        ? value.toISOString() : undefined, type: this.fieldType});
  }

  openPicker(): void {
    if (!this.disabled && !this.readonly) {
      this.picker?.open();
    }
  }
}
