<span [className]="getAvatarClassName(size)" *ngIf="size !== 'xl'">
  <img
    *ngIf="this.entityWithAvatar?.avatar"
    alt="{{ this.entityWithAvatar?.name }}"
    [title]="this.entityWithAvatar?.name"
    src="{{this.entityWithAvatar?.avatar}}"
    class="rounded-circle avatar-sm"
  />
  <div *ngIf="!this.entityWithAvatar?.avatar">
    {{this.entityWithAvatar?.name?.substring(0, 1)?.toUpperCase()}}
  </div>
</span>

<div class="card-profile-image icon-wrapper" *ngIf="size === 'xl'">
  <a href="javascript:void(0)" (click)="avatarInputFile.click()">
    <img
      *ngIf="this.entityWithAvatar?.avatar"
      alt="Image placeholder"
      src="{{this.entityWithAvatar?.avatar}}"
      class="rounded-circle avatar-xl"
      [matTooltip]="'core.avatar.upload' | translate"
    />
    <img
      *ngIf="!this.entityWithAvatar?.avatar"
      src="assets/img/theme/upload-photo-bg.png"
      class="rounded-circle avatar-xl"
      [matTooltip]="'core.avatar.upload' | translate"
    />
  </a>

  <div *ngIf="this.entityWithAvatar?.avatar"
       (click)="deleteAvatar()"
       [matTooltip]="'core.avatar.remove' | translate"
       class="badge badge-circle badge-outline badge-primary bg-white avatar-remove-btn">
    <i class="fa-solid fa-xmark"></i>
  </div>

  <input type="file" #avatarInputFile accept="image/*" (change)="uploadAvatar($event)" />
</div>


