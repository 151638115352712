import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseFieldComponent, FieldType} from '../base-field/base-field.component';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent extends BaseFieldComponent{

  @Input()
  options: any[] = [];

  constructor() {
    super();
    this.fieldType = FieldType.LIST;
  }

  ngOnInit(): void {
    this.formGroup.addControl(this.dbname, this.formControl);
  }

  getGroups(): string[] {
    return [...new Set(this.options?.map(o => o.group))];
  }

  getOptionsOfGroup(group: string): any[] {
    return this.options?.filter(o => o.group === group);
  }

  public changeInput(event: any): void {
    this.changed.emit({id: this.id, name: this.dbname, value: event.value, type: this.fieldType});
  }

  getOption(value: any): any {
    return this.options.find(o => o.id === value);
  }
}
