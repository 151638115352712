import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { SidenavService } from '../service/sidenav.service';
import { MatSidenav } from '@angular/material/sidenav';
import { T } from '@angular/cdk/keycodes';
import { FormField } from './forms/base-field/base-field.component';
import { json } from 'express';
import { AppLayoutService } from '../service/app-layout.service';
import {CdkScrollable, ScrollDispatcher} from '@angular/cdk/scrolling';
import {UpdateApplicationService} from '../../update-application.service';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnDestroy, AfterViewInit {

  @ViewChild('sidenav')
  sidenav!: MatSidenav;

  mobileQuery: MediaQueryList;

  hiddenTenantOptions = true;

  tenantOptions: any[] = [];

  scrollbarWidth = 0;

  stick = false;

  private mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
              private sidenavService: SidenavService,
              private appLayoutService: AppLayoutService,
              private scrollDispatcher: ScrollDispatcher,
              public updateService: UpdateApplicationService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
  }

  changeTenantId(formField: FormField): void {
    this.hideTenantOptions();

    this.appLayoutService.findTenantsByLoggedUser().then(ut => {
      this.tenantOptions = [];
      ut.forEach(item => {
        this.tenantOptions.push(item.tenant);
      });
    });
  }

  showTenantOptions(): void {
    this.hiddenTenantOptions = false;
  }

  hideTenantOptions(): void {
    this.hiddenTenantOptions = true;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
    this.calculateScrollWidth();

    this.sidenav.openedChange.subscribe((changed) => {
      if (changed) {
        this.setFooterSize();
        this.setHeaderSize();
      }
    });
  }

  private calculateScrollWidth(): void {
    // Create the div
    const scrollDiv = document.createElement('div');
    scrollDiv.className = 'scrollbar-measure';
    document.body.appendChild(scrollDiv);

    // Get the scrollbar width
    this.scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    // Delete the div
    document.body.removeChild(scrollDiv);

    if (this.sidenav) {
      this.scrollDispatcher.scrolled()
        .subscribe((cdk) => this.onScroll(cdk));
    }
  }

  onScroll(cdk: void | CdkScrollable): void {
    if (cdk) {
      const scrollPosition = cdk.getElementRef().nativeElement.scrollTop;
      const content: any = document.getElementsByClassName('sidenav-content').item(0);

      const headers: HTMLCollectionOf<any> = document.getElementsByClassName('stick-header');

      let contentPaddingTop = 0;

      for (let i = 0; i < headers.length; i++) {
        const header = headers.item(i);

        // Get the offset position of the navbar
        const sticky = header.offsetTop;

        if (scrollPosition > sticky) {
          this.stick = true;
          if (this.mobileQuery.matches) {
            header.style.width = `${this.sidenav._getWidth() + 8}px`;
          } else {
            header.style.width = `${this.sidenav._getWidth() + this.scrollbarWidth}px`;
          }
          const oldOffsetHeight = header.offsetHeight;
          header.classList.add('sticky');
          contentPaddingTop = (oldOffsetHeight - header.offsetHeight) + header.offsetHeight;

          if (contentPaddingTop !== header.offsetHeight || content.style.paddingTop === '0px') {
            if (content) {
              content.style.paddingTop = `${contentPaddingTop}px`;
            }
          }
        } else {
          this.stick = false;
          header.classList.remove('sticky');
          header.style.width = null;
          header.style.right = null;
          if (content) {
            content.style.paddingTop = `0px`;
          }
        }

        const resizeables: HTMLCollectionOf<any> = document.getElementsByClassName('stick-resize');
        for (let j = 0; j < resizeables.length; j++) {
          const minHeight = resizeables.item(j).dataset.minheight;
          const maxHeight = resizeables.item(j).dataset.maxheight;

          const size = (maxHeight - scrollPosition > maxHeight ? maxHeight : (maxHeight - scrollPosition < minHeight ? minHeight : maxHeight - scrollPosition));

          resizeables.item(j).style.height = size + 'px';
          if (resizeables.item(j).className.includes('keep-aspect')) {
            const maxWidth = resizeables.item(j).dataset.maxwidth;
            resizeables.item(j).style.width = (maxWidth ? size / maxHeight * maxWidth : size) + 'px';
          }
        }
      }
    }
  }

  reloadPage(): void {
    this.updateService.updateAvailable = false;
    window.location.reload();
  }

  private setHeaderSize(): void {
    const headers: HTMLCollectionOf<any> | null = document.getElementsByClassName('fixed-header');

    const content: any = document.getElementsByClassName('sidenav-content').item(0);
    for (let i = 0; i < headers.length; i++) {
      const header = headers.item(i);
      header.style.width = `${this.sidenav._getWidth() + this.scrollbarWidth}px`;
      content.style.paddingTop = `${header.offsetHeight}px`;
    }
  }

  private setFooterSize(): void {
    const footers: HTMLCollectionOf<any> | null = document.getElementsByClassName('fixed-footer');

    const content: any = document.getElementsByClassName('sidenav-content').item(0);
    for (let i = 0; i < footers.length; i++) {
      const footer = footers.item(i);
      footer.style.width = `${this.sidenav._getWidth()}px`;
      content.style.paddingBottom = `${footer.offsetHeight}px`;
    }
  }

}
