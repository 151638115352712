import { Component, Input, OnInit } from '@angular/core';
import { Card, CardType } from '../../domain/card';

@Component({
  selector: 'app-indicator',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss']
})
export class IndicatorComponent implements OnInit {

  @Input() card: Card = { id: '', name: '', description: '', type: CardType.CHART, subtype: '', position: 0,
                          verticalSize: 0, horizontalSize: 0, actions: [] };
  @Input() data: any = {};
  @Input() cardStyle = 'default';

  constructor() { }

  ngOnInit(): void {
  }

  getFirstData(): any {
    return this.data?.data && this.data.data.length > 0 ? this.data.data[0] : { value: '', detail: '' };
  }
}
