import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/infrastructure/service/api.service';
import { AutoSaveService } from 'src/app/modules/auto-save/service/auto-save.service';

@Injectable({
  providedIn: 'root'
})
export class AppLayoutService extends ApiService {


  private serviceUrl = 'auth';


  constructor(http: HttpClient) {
    super(http);

  }

  findTenantsByLoggedUser(): Promise<any[]> {
    return super.get(`${this.serviceUrl}/tenants`).toPromise();
  }

}
