<div [className]="getFormGroupClass()" [formGroup]="formGroup">
  <label class="form-control-label mb-0" [for]="dbname" *ngIf="name">{{name}}</label>
  <mat-select *ngIf="getGroups().length > 1" #selectInput [formControl]="formControl"
              [className]="getFormControlClass()"
              [attr.required]="this.required ? true : null"
              [attr.disabled]="(this.disabled ? true : null)  || this.readonly"
              [attr.data-entity-name]="dbname" (selectionChange)="changeInput($event)">
    <mat-select-trigger>
      <span [innerHTML]="getOption(formControl.value)?.name"></span>
    </mat-select-trigger>
    <mat-optgroup *ngFor="let group of getGroups()" [label]="group">
      <mat-option *ngFor="let option of getOptionsOfGroup(group)" [value]="option.id">
        {{option.name}}
      </mat-option>
    </mat-optgroup>
  </mat-select>
  <mat-select *ngIf="getGroups().length == 1" #selectInput [formControl]="formControl"
              [className]="getFormControlClass()"
              [attr.required]="this.required ? true : null"
              [attr.disabled]="(this.disabled ? true : null)  || this.readonly"
              [attr.data-entity-name]="dbname" (selectionChange)="changeInput($event)">
    <mat-select-trigger>
      <span [innerHTML]="getOption(formControl.value)?.name"></span>
    </mat-select-trigger>
    <mat-option *ngFor="let option of options" [value]="option.id">
      <span [innerHTML]="option.name"></span>
    </mat-option>
  </mat-select>
</div>
