export interface QueueAutoSaveFields {
  name: string;
  value?: string;
  invalid: boolean;
}

export interface QueueAutoSave {
  id: string | undefined;
  timestamp: number;
  status: QueueAutoSaveStatus;
  operation: QueueAutoSaveOperation;
  fields?: QueueAutoSaveFields[];
  parentId?: string;
}

export enum QueueAutoSaveOperation {
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

export enum QueueAutoSaveStatus {
  PENDING = 'PENDING',
  SENDING = 'SENDING',
  DONE = 'DONE',
  ERROR = 'ERROR'
}
