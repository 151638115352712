import {Injectable} from '@angular/core';
import {ApiService} from '../../infrastructure/service/api.service';
import {HttpClient} from '@angular/common/http';
import {Menu} from '../domain/menu';

@Injectable({
  providedIn: 'root'
})
export class MenuService extends ApiService {

  private serviceUrl = 'menu';

  public getMenu(): Promise<Menu[]> {
    return super.get(this.serviceUrl).toPromise();
  }

}
