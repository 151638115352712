import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ROUTES} from '../sidenav/sidenav.component';
import {AuthService} from '../../../infrastructure/auth/auth.service';
import {UserService} from '../../../infrastructure/auth/user.service';
import {User} from '../../../infrastructure/auth/user';
import {ToolbarService} from '../../service/toolbar.service';
import {MatDialog} from '@angular/material/dialog';
import {SwitchTenantComponent} from './switch-tenant/switch-tenant.component';
import {GeneralSearch} from '../../../modules/general_search/domain/general-search';
import {NgbDropdown} from '@ng-bootstrap/ng-bootstrap';
import {GeneralSearchService} from '../../../modules/general_search/service/general-search.service';
import {Router} from '@angular/router';
import {UserTenant} from '../../../infrastructure/auth/user-tenant';
import {AppNotification, AppNotificationType} from '../../../modules/core/notification/domain/notification';
import {NotificationService} from '../../service/notification.service';
import {WebSocketService, WebSocketServiceInstance} from '../../../infrastructure/service/web-socket.service';
import {WebSocketHandler} from '../../../infrastructure/service/web-socket-handler';
import {SnackbarService} from '../../../infrastructure/service/snackbar.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy, WebSocketHandler {

  public focus = false;
  public listTitles!: any[];

  private topic = '/notification';
  private websocketInstance!: WebSocketServiceInstance;
  notifications: AppNotification[] = [];

  @Input()
  snav: any;

  @Input()
  sidenav: any;

  user!: User;

  /* userTenants!: UserTenant[]; */

  @Output()
  changeTenantOptionsEvent = new EventEmitter();

  // General Search
  generalSearchOptions!: GeneralSearch[];

  filterText = '';

  @ViewChild('generalSearchDropdown', {read: NgbDropdown})
  public generalSearchDropdown!: NgbDropdown;

  filterChangeTimeout: any;
  filterChangeSecondsInterval = 300;

  readTimer: any;

  constructor(private authService: AuthService,
              private userService: UserService,
              private toolbarService: ToolbarService,
              private notificationService: NotificationService,
              private snackbarService: SnackbarService,
              private websocket: WebSocketService,
              public dialog: MatDialog,
              private router: Router,
              private generalSearchService: GeneralSearchService) {
  }

  ngOnInit(): void {
    this.notificationService.findAll().then(n => this.notifications = n);
    this.websocketInstance = this.websocket.connect(this.topic, this);

    this.listTitles = ROUTES.filter(listTitle => listTitle);

    this.authService.checkCredentials().then(auth => {
      if (auth) {
        this.userService.getLoggedUser().then((u) => {
          this.user = u;
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.websocketInstance.disconnect();

    // this.routeSub.unsubscribe();
  }

  handleMessage(message: string): void {
    const item: AppNotification = JSON.parse(message);

    const idx = this.notifications.findIndex(n => n.id === item.id);
    if (idx !== -1) {
      this.notifications[idx] = item;
    } else {
      this.notifications.unshift(item);
    }

    this.handleBrowserNotification(item);

    const description = item.description.length < 100 ? item.description : item.description + '...';

    switch (item.type) {
      case AppNotificationType.SUCCESS:
        this.snackbarService.success(description);
        break;
      case AppNotificationType.ERROR:
        this.snackbarService.error(description);
        break;
      case AppNotificationType.WARNING:
        this.snackbarService.warning(description);
        break;
      case AppNotificationType.INFORMATION:
        this.snackbarService.info(description);
        break;
      default:
        this.snackbarService.info(description);
    }

    this.notifications = this.notifications.sort((a: AppNotification, b: AppNotification) => a.updated.getTime() - b.updated.getTime());
  }

  /* showTenantOptions(): void {
    const dialogRef = this.dialog.open(SwitchTenantComponent, {
      data: this.userTenants,
      // position: position,
      // height: '100vh'
    });
  } */

  logout(): void {
    this.authService.logout();
  }

  getTitle(): string {
    return this.toolbarService.getTitle() || '';
  }

  getToolbar(): any[] {
    return this.toolbarService.getToolbar();
  }

  executeCallback(callback: any, params: any): void {
    this.toolbarService.executeCallback(callback, params);
  }

  generalSearchInput(event: any): void {
    clearTimeout(this.filterChangeTimeout);
    if (event.target.value !== '') {
      this.filterChangeTimeout = setTimeout(() => {
          this.search(event.target.value);

        }, this.filterChangeSecondsInterval
      );
    } else {
      this.generalSearchDropdown.close();
    }
  }

  search(texto: string): void {
    if (texto !== '') {
      this.generalSearchService.findByText(texto).then(generalSearchs => {
        this.generalSearchOptions = generalSearchs;
        this.generalSearchDropdown.open();

      }).catch(err => console.log('ERRO: ', JSON.stringify(err)));
    } else {
      this.generalSearchOptions = [];
    }
  }

  generalSearchFocus(focus: boolean): void {
    this.focus = focus;
    if (!focus) {
      this.filterText = '';
    }
  }

  generalSearchChange(event: any): void {
    event.target.value = '';
  }

  hasUnreadNotification(): boolean {
    return this.notifications.some(n => !n.read);
  }

  startReadTimer(notification: AppNotification): void {
    this.cancelReadTimer();
    this.readTimer = setTimeout(() => this.markAsRead(notification), 2000);
  }

  markAsRead(notification: AppNotification): void {
    if (!notification.read) {
      this.notificationService.markAsRead(notification.id).then(() => {
        notification.read = true;
        const idx = this.notifications.findIndex(n => n.id === notification.id);
        this.notifications[idx] = notification;
        this.notifications = [...this.notifications];
      });
    }
  }

  cancelReadTimer(): void {
    clearTimeout(this.readTimer);
  }

  readAndRedirect(notification: AppNotification): void {
    this.markAsRead(notification);
    if (notification.link) {
      this.router.navigateByUrl(notification.link);
    }
  }

  markAllAsRead(): void {
    this.notificationService.markAllAsRead().then(() => {
      this.notifications.forEach(n => n.read = true);
      this.notifications = [...this.notifications];
    });
  }

  generalSearchEnter(event: any): void {
    event.preventDefault();

    const generalSearchWithLinks = this.generalSearchOptions.filter(g => g.generalEntity.length > 0);
    if (generalSearchWithLinks.length >= 1) {
      this.router.navigate(['/', this.generalSearchOptions[0].route, this.generalSearchOptions[0].generalEntity[0].id]);
    }
  }

  handleBrowserNotification(appNotification: AppNotification): void {

    if (Notification.permission === 'granted') {
      const notification = new Notification(appNotification.title, {
        body: appNotification.description,
        data: {link: appNotification.link}
      });

      notification.onclick = () => {
        this.router.navigate([notification.data.link]);
      };
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          const notification = new Notification(appNotification.title, {
            body: appNotification.description,
            data: {link: appNotification.link}
          });

          notification.onclick = () => {
            this.router.navigate([notification.data.link]);
          };
        }
      });
    }

  }
}


