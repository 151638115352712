import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {SidenavService} from '../../../app-layout/service/sidenav.service';
import {WebSocketService} from '../../../infrastructure/service/web-socket.service';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {MatSidenav} from '@angular/material/sidenav';
import {filter, map} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {Task} from '../../../modules/task/domain/task';
import {Ticket} from '../domain/ticket';
import {CdkScrollable, ScrollDispatcher} from '@angular/cdk/scrolling';

@Component({
  selector: 'app-contact-window',
  templateUrl: './contact-window.component.html',
  styleUrls: ['./contact-window.component.scss']
})
export class ContactWindowComponent implements AfterViewInit {

  routeUrl = 'support';
  private routeSub!: Subscription;

  listView = true;

  @ViewChild('sidenav')
  matSidenav!: MatSidenav;

  @ViewChild('editContainer')
  editContainer!: ElementRef<HTMLInputElement>;

  @ViewChild('listContainer')
  listContainer!: ElementRef<HTMLInputElement>;

  mobileQuery: MediaQueryList;
  scrollbarWidth = 0;
  stick = false;

  private mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
              private sidenav: SidenavService,
              private websocket: WebSocketService,
              private router: Router,
              private scrollDispatcher: ScrollDispatcher) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
  }

  ngAfterViewInit(): void {
    this.sidenav.setSidenav(this.matSidenav);

    this.routeSub = this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        map(event => event as NavigationStart),
      )
      .subscribe(
        event => {
          if (event.url.includes(`/${this.routeUrl}`)) {
            this.handleUrlChanged(event.url);
          }
        }
      );

    this.handleUrlChanged(this.router.url);

    this.calculateScrollWidth();

    this.matSidenav.openedChange.subscribe((changed) => {
      if (changed) {
        this.setFooterSize();
        this.setHeaderSize();
      }
    });
  }

  private handleUrlChanged(urlString: string): void {
    const url: string[] = urlString.split('/');

    if (!url[url.length - 1].startsWith(this.routeUrl) && url[url.length - 1] !== '') {
      this.sidenav.open(urlString, `/${this.routeUrl}`);
      this.sidenav.changed.subscribe((ticket: Ticket) => this.onEditChange(ticket));
    }
  }

  toggle(): void {
    if (this.listView) {
      this.editContainer?.nativeElement.classList.remove('hidden-right');
      this.listContainer?.nativeElement.classList.add('hidden-left');
    } else {
      this.editContainer?.nativeElement.classList.add('hidden-right');
      this.listContainer?.nativeElement.classList.remove('hidden-left');
    }

    this.listView = !this.listView;
  }

  private onEditChange(ticket: Ticket): void {

  }

  private calculateScrollWidth(): void {
    // Create the div
    const scrollDiv = document.createElement('div');
    scrollDiv.className = 'scrollbar-measure';
    document.body.appendChild(scrollDiv);

    // Get the scrollbar width
    this.scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

    // Delete the div
    document.body.removeChild(scrollDiv);

    if (this.sidenav) {
      this.scrollDispatcher.scrolled()
        .subscribe((cdk) => this.onScroll(cdk));
    }
  }

  // TODO: Criar um novo "app-layout" para paginas fora do sistema como esta, colocar as funções de footersize e headersize lá
  onScroll(cdk: void | CdkScrollable): void {
    if (cdk) {
      const scrollPosition = cdk.getElementRef().nativeElement.scrollTop;
      const content: any = document.getElementsByClassName('sidenav-content').item(0);

      const headers: HTMLCollectionOf<any> = document.getElementsByClassName('stick-header');

      let contentPaddingTop = 0;

      for (let i = 0; i < headers.length; i++) {
        const header = headers.item(i);

        // Get the offset position of the navbar
        const sticky = header.offsetTop;

        if (scrollPosition > sticky) {
          this.stick = true;
          header.style.width = `${this.matSidenav._getWidth() + this.scrollbarWidth}px`;
          const oldOffsetHeight = header.offsetHeight;
          header.classList.add('sticky');
          contentPaddingTop = (oldOffsetHeight - header.offsetHeight) + header.offsetHeight;

          if (contentPaddingTop !== header.offsetHeight || content.style.paddingTop === '0px') {
            if (content) {
              content.style.paddingTop = `${contentPaddingTop}px`;
            }
          }
        } else {
          this.stick = false;
          header.classList.remove('sticky');
          header.style.width = null;
          header.style.right = null;
          if (content) {
            content.style.paddingTop = `0px`;
          }
        }

        const resizeables: HTMLCollectionOf<any> = document.getElementsByClassName('stick-resize');
        for (let j = 0; j < resizeables.length; j++) {
          const minHeight = resizeables.item(j).dataset.minheight;
          const maxHeight = resizeables.item(j).dataset.maxheight;

          const size = (maxHeight - scrollPosition > maxHeight ?
            maxHeight : (maxHeight - scrollPosition < minHeight ? minHeight : maxHeight - scrollPosition));

          resizeables.item(j).style.height = size + 'px';
          if (resizeables.item(j).className.includes('keep-aspect')) {
            const maxWidth = resizeables.item(j).dataset.maxwidth;
            resizeables.item(j).style.width = (maxWidth ? size / maxHeight * maxWidth : size) + 'px';
          }
        }
      }
    }
  }

  private setHeaderSize(): void {
    const headers: HTMLCollectionOf<any> | null = document.getElementsByClassName('fixed-header');

    const content: any = document.getElementsByClassName('sidenav-content').item(0);
    for (let i = 0; i < headers.length; i++) {
      const header = headers.item(i);
      header.style.width = `${this.matSidenav._getWidth() + this.scrollbarWidth}px`;
      content.style.paddingTop = `${header.offsetHeight}px`;
    }
  }

  private setFooterSize(): void {
    const footers: HTMLCollectionOf<any> | null = document.getElementsByClassName('fixed-footer');

    const content: any = document.getElementsByClassName('sidenav-content').item(0);
    for (let i = 0; i < footers.length; i++) {
      const footer = footers.item(i);
      footer.style.width = `${this.matSidenav._getWidth()}px`;
      content.style.paddingBottom = `${footer.offsetHeight}px`;
    }
  }
}
