import {Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren} from '@angular/core';
import {Field} from '../../../../../modules/crud/domain/window';
import {FormGroup} from '@angular/forms';
import {CrudField} from '../../../../../modules/crud/domain/crud-generic-object';
import {CrudFieldType} from '../../../../../modules/crud/domain/crud-field-type';
import {Card, CardType} from '../../../../../modules/card/domain/card';
import {ParamMap} from '@angular/router';
import {CrudColumnType} from '../../../../../modules/crud/domain/crud-column-type';
import {FormField} from '../../../forms/base-field/base-field.component';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss']
})
export class FieldComponent implements OnInit, OnChanges {

  crudColumnType: typeof CrudColumnType = CrudColumnType;
  crudFieldType: typeof CrudFieldType = CrudFieldType;

  @Input()
  showHeader = true;

  @Input()
  formGroup!: FormGroup;

  @Input()
  field!: Field;

  @Input()
  data!: any;

  @Input()
  dataString!: string;

  @Input()
  params!: ParamMap;

  @Output()
  changed = new EventEmitter<FormField>()

  @Output()
  executeAction = new EventEmitter<string>()

  constructor() { }

  public onChangeInput(field: FormField): void {
    this.changed.emit(field);
  }

  ngOnInit(): void {
  }

  public getCard(field: Field): Card {
    return field.card ? field.card : {
      actions: [],
      description: '',
      horizontalSize: 0,
      id: '',
      name: '',
      position: 0,
      subtype: '',
      type: CardType.HTML,
      verticalSize: 0
    };
  }

  public onExecuteAction(actionId: string | undefined): void {
    if (actionId) {
      this.executeAction.emit(actionId);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('fieldComponent', changes);
  }

  getFieldData(dbname: string): any {
    return this.data ? this.data[dbname] : undefined;
  }
}
