<div [className]="getFormGroupClass()" [formGroup]="formGroup">
  <label class="form-control-label mb-0" [for]="dbname" *ngIf="name">{{name}}</label>
  <div [className]="getInputGroupClass()">
    <input *ngIf="customFormat"
           [className]="getFormControlClass()"
           (mouseover)="setHover(true)"
           (mouseout)="setHover(false)"
           (focusin)="setFocus(true)"
           (focusout)="setFocus(false)"
           [value]="this.formControl.value | date: customFormat"
           [placeholder]="description"
           [required]="required"
           (keydown)="skipChanges()"
           (click)="picker.open()"
    >
    <input *ngIf="!customFormat"
           matInput [matDatepicker]="picker" [formControlName]="dbname" [className]="getFormControlClass()"
           (dateChange)="changeInput($event)" [placeholder]="description"
           [readonly]="this.readonly"
           [attr.required]="this.required ? true : null"
           [attr.disabled]="this.disabled ? true : null"
           (mouseover)="setHover(true)"
           (mouseout)="setHover(false)"
           (focusin)="setFocus(true)"
           (focusout)="setFocus(false)"
    >
    <div class="input-group-append"
         (mouseover)="setHover(true)"
         (mouseout)="setHover(false)"
         (focusin)="setFocus(true)"
         (focusout)="setFocus(false)"
    >
      <span [className]="'input-group-text ' + (disabled ? 'input-group-disabled' : '') + ' ' + this.styleClass">
        <i class="ni ni-calendar-grid-58 cursor-pointer" (click)="openPicker()"></i>
      </span>
    </div>
  </div>
</div>

<input *ngIf="customFormat"
       matInput [matDatepicker]="picker" [formControlName]="dbname"
       (dateChange)="changeInput($event)" [placeholder]="description" [required]="required"
       (mouseover)="setHover(true)"
       (mouseout)="setHover(false)"
       (focusin)="setFocus(true)"
       (focusout)="setFocus(false)"
       class="input-date-hidden"
>
<mat-datepicker #picker
                [startView]="startView"
                (monthSelected)="verifyClose($event, 'month', picker)"
                (yearSelected)="verifyClose($event, 'year', picker)"
></mat-datepicker>
