import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Field, Window} from '../../../../../modules/crud/domain/window';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {CrudField} from '../../../../../modules/crud/domain/crud-generic-object';
import {ActionService} from '../../../../../modules/action/service/action.service';
import {ParamMap, Router} from '@angular/router';
import {CrudService} from '../../../../../modules/crud/service/crud.service';
import {WindowService} from '../../../../../modules/crud/service/window.service';
import {SnackbarService} from '../../../../../infrastructure/service/snackbar.service';
import {ActionRequestParams} from '../../../../../modules/action/domain/action-request';
import {AutoSaveService} from '../../../../../modules/auto-save/service/auto-save.service';
import {QueueAutoSave, QueueAutoSaveFields} from '../../../../../modules/auto-save/domain/queue-auto-save';
import {WindowType} from '../../../../../modules/crud/domain/window-type';
import {FieldComponent} from '../field/field.component';
import {FieldType, FormField} from '../../../forms/base-field/base-field.component';
import { CrudFieldType } from 'src/app/modules/crud/domain/crud-field-type';
import { ActionType } from 'src/app/modules/action/domain/action-type';

const AUTO_SAVE_PREFIX  = 'crud/';

@Component({
  selector: 'app-window',
  templateUrl: './window.component.html',
  styleUrls: ['./window.component.scss']
})
export class WindowComponent implements OnInit, OnDestroy {

  window!: Window;

  data!: any;

  @Input()
  showHeader = true;

  @Input()
  id!: string;

  @Input()
  parentId!: string;

  @Input()
  windowPath!: string;

  @Input()
  path!: string;

  @Input()
  params!: ParamMap;

  @Input()
  redirectAfterSave = false;

  @ViewChild(FieldComponent)
  fieldComponents!: FieldComponent[];

  @Output()
  windowFound = new EventEmitter<Window>();

  formHeader: FormGroup;

  constructor(
    private actionService: ActionService,
    private snackBar: SnackbarService,
    private windowService: WindowService,
    private service: CrudService,
    private autoSaveService: AutoSaveService,
    private router: Router) {
    this.formHeader = new FormGroup({id: new FormControl()});
  }

  ngOnDestroy(): void {
    if (this.window?.type === WindowType.CRUD && !this.id) {
      const autoSaveFields: QueueAutoSaveFields[] = [];
      Object.keys(this.formHeader.controls).forEach(k => {
        const formControl: any = this.formHeader.controls[k];

        if ((formControl.value && formControl.value !== '') || formControl.invalid) {
          autoSaveFields.push(this.toAutoSaveField(formControl));
        }

      });

      this.autoSaveService.onExit(AUTO_SAVE_PREFIX + this.path, this.id, this.parentId, autoSaveFields);
    }
  }

  ngOnInit(): void {
    this.findWindow(this.path);
  }

   findWindow(window: string): void {
    this.windowService.getWindow(window).then((w: Window) => {
      this.window = w;
      this.windowPath = w.windowPath;
      this.windowFound.emit(w);

      if (this.window?.type === WindowType.CRUD) {
        if (this.id) {
          this.findById(window, this.id);
        } else {
          setTimeout(() => {
            const autoSaveRecord: QueueAutoSave | undefined = this.autoSaveService.findDraftRecord(AUTO_SAVE_PREFIX + this.path, this.id);
            if (autoSaveRecord) {
              if (!this.data) {
                this.data = {};
                this.getFields().forEach(f => this.data[f.dbname] = '');
              }
              autoSaveRecord.fields?.filter(f => f.value).forEach(f => {
                this.data[f.name] = f.value;
              });
            }
          });
        }
      } else {
        this.data = {id: this.id};
      }
    });
  }

  private findById(window: string, id: string): void {
    this.service.findById(window, id).then((r: any) => {
      this.data = r._data[0];

      this.window.fields?.
      filter(f => f.calculatedValue).
      forEach(f => this.calculatedValueForFields(f));
    });
  }

  getMandatoryFields(): Field[] {
    return this.getFields().filter(f => f.mandatory);
  }

  getFields(): Field[] {
    if (this.window && this.window.fields) {
      return this.window.fields.filter(f => !f.side && !f.isParentColumn && !(f.action?.type === ActionType.SPEED_DIAL));
    } else {
      return [];
    }
  }

  getFieldByDbName(dbname: string): Field | undefined {
    return this.getFields().find(f => f.dbname === dbname);
  }

  onFieldChange(crudField: FormField): void {
    if (this.window?.type === WindowType.CRUD) {
      const field: Field | undefined = this.getFieldByDbName(crudField.name);

      if (this.data) {
        this.data[crudField.name] = crudField.value;
        this.data = Object.assign({}, this.data);
      }

      this.autoSave(crudField);

      if (field) {
        this.verifyCalculatedValues(field);
      }
    }
  }

   autoSave(crudField?: FormField): void {
    if (this.id && crudField !== undefined) {
      this.autoSaveService.queue(AUTO_SAVE_PREFIX + this.path,
        [this.toAutoSaveField(this.formHeader.controls[crudField.name])], this.parentId, this.id);
    } else {
      const autoSaveFields: QueueAutoSaveFields[] = [];
      Object.keys(this.formHeader.controls).forEach(k => {
        const formControl: any = this.formHeader.controls[k];

        if ((formControl.value && formControl.value !== '') || formControl.invalid) {
          autoSaveFields.push(this.toAutoSaveField(formControl));
        }

      });

      if (this.getMandatoryFields().every(f => autoSaveFields.some(a => a.name === f.dbname))) {
        const savePromise: Promise<string> | undefined = this.autoSaveService
          .queue(AUTO_SAVE_PREFIX + this.path, autoSaveFields, this.parentId);

        if (savePromise) {
          savePromise
            .then((id) => {
              this.id = id;
              if (this.redirectAfterSave) {
                this.router.navigate(['windows', this.path, this.id]);
              }
            }).catch(reason => {
            // TODO: Verificar campo na mensagem de error e marcá-lo
            //  com error para avisar o usuário
            Object.keys(this.formHeader.controls).forEach(k => {
              if (reason?.error?.toLowerCase()?.includes('(' + k + ')') || reason?.error?.toLowerCase()?.includes('"' + k + '"')) {
                this.formHeader.controls[k].setErrors({invalid: true});
              }
            });
          });
        }
      }
    }
  }

  onExecuteAction(actionId: string, params?: any): void {
    const requestParams: ActionRequestParams[] = [];

    if (params) {
      Object.keys(params).forEach((key) => {
        const requestParam: ActionRequestParams = {name: key, value: params[key]};
        requestParams.push(requestParam);
      });
    }

    const request = {
      recordId: [this?.data?.id],
      params: requestParams
    };
    this.executeAction(actionId, request);
  }

  executeAction(actionId: string, params?: any): void {
    const request = params || {
      recordId: [this.id]
    };
    this.actionService.executeAction(actionId, request).then((r) => {
      this.snackBar.info(r.message || 'Função executada com sucesso!');
    }).catch((r) => {
      this.snackBar.error(r.message || 'Error:' + r.message);
    });
  }

  hasSideFields(): boolean {
    return this.getSideFields().length > 0;
  }

  getSideFields(): Field[] {
    if (this?.window?.fields) {
      return this.window.fields.filter(f => f.side && !f.isParentColumn);
    } else {
      return [];
    }
  }

  getActions(): Field[] {
    if (this?.window?.fields) {
      return this.window.fields.filter(f => f.type == CrudFieldType.BUTTON);
    } else {
      return [];
    }
  }

  hasActions(): boolean {
    return this.getActions().length > 0;
  }

  getSpeedDialButtons(): any[] {
    if (this.hasSpeedDialActions()) {
      const speedDialButtons: any[] = [];
      this.getActions().filter(a => a.action?.type === ActionType.SPEED_DIAL).forEach(a => speedDialButtons.push({icon: a.action?.icon, title: a.name, action: a.action?.link}));
      return speedDialButtons;
    } else {
      return [];
    }
  }

  getSpeedDialButton(): any {
    if (this.hasSpeedDialActions()) {
      const speedDialButton = this.getActions()[0];
      return {icon: speedDialButton.action?.icon, title: speedDialButton.name, action: speedDialButton.action?.link};
    } else {
      return {icon: 'add', title: 'Not defined', action: 'add'};
    }
  }

  hasSpeedDialActions(): boolean {
    return this.getActions().filter(a => a.action?.type === ActionType.SPEED_DIAL).length > 0;
  }

  hasMoreThanOneSpeedDial(): boolean {
    return this.getSpeedDialButtons.length > 1;
  }

  private toAutoSaveField(formControl: AbstractControl): QueueAutoSaveFields {
    return {name: this.getControlName(formControl), value: formControl.value?.id || formControl.value, invalid: formControl.invalid};
  }

  private getControlName(c: AbstractControl): string {
    const formGroup: any = c.parent?.controls;
    return formGroup ? Object.keys(formGroup).find(name => c === formGroup[name]) || '' : '';
  }

  getParentField(): Field[] {
    if (this?.window?.fields) {
      return this.window.fields.filter(f => f.isParentColumn);
    } else {
      return [];
    }
  }

  // private redrawChildren(field: Field | undefined, crudField: CrudField): void {
  //   const fieldsToRedraw: Field[] | undefined = this?.window?.fields?.filter(f => f.whereClause.includes(crudField.name));
  //   fieldsToRedraw?.forEach(f => {
  //     const fieldComponent: FieldComponent | undefined  = this?.fieldComponents?.find(fc => fc.field === f);
  //     fieldComponent?.params;
  //   });
  // }
  displayLogic(field: Field): boolean {
    if (this.data && field.displayClause) {
      let displayClause: string = this.replaceValues(field.displayClause);

      // tslint:disable-next-line:no-eval
      return displayClause.includes('#{') ? false : eval(displayClause);
    } else if (field.displayClause) {
      return false;
    }
    return true;
  }

  calculatedValueForFields(field: Field): void {
    if (field.calculatedValue) {
      const calculatedValue = this.replaceValues(field.calculatedValue);
      if (!calculatedValue.includes('#{')) {
        this.data[field.dbname] = eval(calculatedValue);
      } else {
        console.log("Error on calculatedField: " + calculatedValue);
      }
    }
  }

  verifyCalculatedValues(field: Field): void {
    this.window.fields?.
      filter(f => f.calculatedValue).
      forEach(f => {
        if (f.calculatedValue?.includes(field.dbname)) {
          this.calculatedValueForFields(f);
        }
      }
    );
  }

  replaceValues(clause: string): string {
    let replacedClause: string = clause;
    Object.keys(this.data).forEach((key) => {
      replacedClause = replacedClause.replace('#{' + key + '}', '"' + this.data[key] + '"');
    });
    return replacedClause;
  }

  activateOrInactivate(): void {
    if (this?.data?.active === 'true') {
      this.service.inactivate(this.path, this.id)
        .then(() => this.data.active = 'false')
        .catch(() => this.data.active = 'true');
    } else {
      this.service.reactivate(this.path, this.id)
        .then(() => this.data.active = 'true')
        .catch(() => this.data.active = 'false');
    }
  }
}
