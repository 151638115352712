import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActionRequest} from '../domain/action-request';
import {ActionResponse} from '../domain/action-response';
import {ApiService} from '../../../infrastructure/service/api.service';

@Injectable({
  providedIn: 'root'
})
export class ActionService extends ApiService {

  private serviceUrl = 'action';

  public executeAction(id: string, request: ActionRequest): Promise<ActionResponse> {
    const methodUrl = this.serviceUrl + '/{id}';
    return super.post(methodUrl.replace('{id}', id), request).toPromise();
  }
}
