import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Window} from '../domain/window';
import {ApiService} from '../../../infrastructure/service/api.service';

@Injectable({
  providedIn: 'root'
})
export class WindowService extends ApiService {

  private serviceUrl = 'windows/{path}';

  public getWindow(path: string): Promise<Window> {
    return super.get(this.serviceUrl.replace('{path}', path)).toPromise();
  }

}
