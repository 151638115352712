<form [formGroup]="formHeader">
  <input type="hidden" [formControlName]="'id'" />
  <div class="pl-4 pr-4">
    <app-input-hidden (changed)="onFieldChange($event)" [dbname]="field.dbname" [formGroup]="formHeader" [data]="parentId" *ngFor="let field of getParentField(); index as i"></app-input-hidden>
    <div class="row">
      <div class="mb-2 col-sm-12" [ngClass]="field.horizontalSize ? 'col-xl-' + field.horizontalSize : 'col-xl-6'"
           *ngFor="let field of getFields(); index as i">
        <app-field *ngIf="displayLogic(field)"
                   (changed)="onFieldChange($event)"
                   (executeAction)="onExecuteAction($event)"
                   [field]="field" [formGroup]="formHeader"
                   [data]="data"
                   [params]="params"
                   [showHeader]="showHeader">
        </app-field>
      </div>
    </div>
  </div>
</form>
<div class="pl-4 pr-4" *ngIf="id">
  <mat-tab-group>
    <mat-tab *ngFor="let tab of window?.tabs; index as i" [label]="tab.name">
      <app-tab [tab]="tab" [parentId]="id" [windowPath]="windowPath"></app-tab>
    </mat-tab>
  </mat-tab-group>
</div>
