import { Injectable } from '@angular/core';
import {ApiService} from '../../../infrastructure/service/api.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CrudField} from '../domain/crud-generic-object';
import {FieldOptionData} from '../domain/field-option-data';

@Injectable({
  providedIn: 'root'
})
export class TableService extends ApiService {

  private serviceUrl = `crud/table`;

  public findById(tableId: string, id: string): Promise<FieldOptionData[]> {
    const methodUrl = this.serviceUrl + `/${tableId}/${id}`;
    return super.get(methodUrl).toPromise();
  }

  public findByDisplayText(tableId: string, filter?: string, requestBody?: any): Promise<FieldOptionData[]> {
    const methodUrl = this.serviceUrl + `/${tableId}`;
    const httpParams: HttpParams = new HttpParams();
    httpParams.set('filter', filter || '');
    return super.post(methodUrl, requestBody, httpParams).toPromise();
  }

}
