import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {AppLayoutComponent} from './app-layout/components/app-layout.component';
import {AuthGuard} from './infrastructure/auth/auth.guard';
import { DesignTestsComponent } from './pages/design-tests/design-tests.component';
import {ContactEditComponent} from './pages/contact/contact-edit/contact-edit.component';
import {ContactWindowComponent} from './pages/contact/contact-window/contact-window.component';
import {PlanComponent} from './pages/plan/plan.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'plan',
    component: PlanComponent
  },
  {
    path: 'contact',
    component: ContactWindowComponent,
    canActivate: [AuthGuard], // TODO: Retirar a necessidade de estar logado para abrir chamados
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule),
  },
  {
    path: 'support',
    component: ContactWindowComponent,
    canActivate: [AuthGuard], // TODO: Retirar a necessidade de estar logado para abrir chamados
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule),
  },
  {
    path: 'design-tests',
    component: DesignTestsComponent
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./app-layout/app-layout.module').then(m => m.AppLayoutModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
