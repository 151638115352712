<div [hidden]="showCommunicationError()">
  <div class="full-screen"></div>
  <div class="loading">
    <mat-progress-spinner
      color="warn"
      mode="indeterminate">
    </mat-progress-spinner>
  </div>
  <div class="loading-message text-center">
    <h2>No communication with the server, wait a moment...</h2>
  </div>
</div>

<router-outlet></router-outlet>
