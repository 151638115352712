import {Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';

@Injectable({
  providedIn: 'root'
})
export class UpdateApplicationService {

  updateAvailable = false;

  everyTenMinutes: any;

  constructor(private updates: SwUpdate) { }

  public checkForUpdates(): void {
    this.everyTenMinutes = setInterval(() => {
      try {
        this.updates.checkForUpdate().then((updateFound: boolean) => {
          if (updateFound) {
            this.updateAvailable = true;
            clearInterval(this.everyTenMinutes);
          }
        });
      } catch (err) {
        console.error('Failed to check for updates:', err);
      }
    }, 1000 * 60 * 10);
  }
}
