import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import {UtilitiesService} from '../../app.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public redirectUri = environment.url;

  private refreshTimeout: any;

  constructor(private http: HttpClient,
              private utilitiesService: UtilitiesService) {
    this.refreshTimeout = setTimeout(() => this.refreshToken(this));
  }

  public retrieveToken(code?: string, refreshToken?: string): Observable<any> {
    const params = new URLSearchParams();
    if (code) {
      params.append('grant_type', 'authorization_code');
      params.append('code', code);
    } else if (refreshToken) {
      params.append('grant_type', 'refresh_token');
      params.append('refresh_token', refreshToken);
    }
    params.append('redirect_uri', localStorage.getItem('redirect_uri') || this.redirectUri);

    const httpHeaders = new HttpHeaders({'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'});

    return this.http.post(environment.oauth2TokenUrl,
      params.toString(), { headers: httpHeaders });
  }

  private refreshToken(ref: any): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const refreshToken: any = localStorage.getItem('refresh_token');
      if (refreshToken) {
        ref.retrieveToken(undefined, refreshToken).toPromise()
          .then((data: any) => {
            ref.saveToken(data);
            resolve(true);
          })
          .catch((err: any) => {
            if (err.status === 403) {
              this.logout();
            } else {
              this.showFailMessage(true);
            }
            resolve(false);
          });
      }
    });
  }

  public saveToken(token: any): void {
    localStorage.setItem('access_token', token.access_token);
    localStorage.setItem('refresh_token', token.refresh_token);
    this.showFailMessage(false);
    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = setTimeout(() => this.refreshToken(this), (token.expires_in - 30) * 1000);
  }

  public checkCredentials(): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      const token = localStorage.getItem('access_token');
      if (token !== undefined && token !== null) {
        const decodedToken: any = jwt_decode.default(token);
        const isValid = Math.floor((new Date()).getTime() / 1000) < decodedToken.exp;
        if (isValid) {
          resolve(true);
        } else {
          this.refreshToken(this).then(refreshed => resolve(refreshed));
        }
      } else {
        localStorage.setItem('redirect_uri', window.location.href);

        // Redirect to oauth2 service login
        window.location.href = environment.oauth2Url +
          '/protocol/openid-connect/auth?client_id=' + encodeURIComponent(environment.oauth2ClientId) +
          '&redirect_uri=' + encodeURIComponent(window.location.href) +
          '&response_type=code&scope=openid';

        resolve(false);
      }
    });
  }

  public logout(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('redirect_uri');

    window.location.href = environment.oauth2Url +
      '/protocol/openid-connect/logout?client_id=' + encodeURIComponent(environment.oauth2ClientId) +
      '&post_logout_redirect_uri=' + encodeURIComponent(environment.url);
  }

  public showFailMessage(show: boolean): void {
    this.utilitiesService.failMessageShow = show;
    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = setTimeout(() => this.refreshToken(this), 10000);
  }

}
