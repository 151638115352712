<div class="row" *ngIf="cardStyle === 'default'">
  <div class="col">
    <h5 class="card-title text-uppercase text-muted mb-0">{{ card.name }}</h5>
    <span class="display-3 font-weight-bold pt-1 mb-0" [innerHTML]="getFirstData()?.value"></span>
    <h6 class="mt-0 mb-0 text-muted text-sm" [innerHTML]="getFirstData()?.detail | safeHtml"></h6>
  </div>
  <div class="col-auto">
    <div class="icon icon-shape text-white rounded-circle shadow" [ngClass]="card.iconColor != null && card.iconColor != '' ? 'bg-' + card.iconColor : 'bg-info'">
      <i [ngClass]="card.icon != null && card.icon != '' ? card.icon : 'fas fa-info'"></i>
    </div>
  </div>
</div>

<div class="d-flex align-items-center" *ngIf="cardStyle === 'minimalist'">
  <div class="icon icon-shape text-white rounded-circle shadow" [ngClass]="card.iconColor != null && card.iconColor != '' ? 'bg-' + card.iconColor : 'bg-info'">
    <i [ngClass]="card.icon != null && card.icon != '' ? card.icon : 'fas fa-info'"></i>
  </div>
  <span class="display-3 font-weight-bold pt-1 mb-0 pr-3 ml-3 mr-3 border-right">{{ getFirstData()?.value }}</span>
  <h5 class="card-title text-uppercase text-muted mb-0">{{ card.name }}</h5>
</div>
