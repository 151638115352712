export class DateConverter {

  static toDate(date: any): Date | undefined {
    if (!date) {
      return undefined;
    }

    if (date.constructor === Date) {
      return date;
    } else if (date.includes('T')) {
      return new Date(date);
    } else {
      const timezoneOffset = new Date().getTimezoneOffset();
      return new Date(new Date(date).getTime() + timezoneOffset * 60000);
    }
  }

  static toTime(time: any): Date | undefined {
    if (!time) {
      return undefined;
    }

    if (time.constructor === Date) {
      return time;
    } else {
      const timezoneOffset = new Date().getTimezoneOffset();
      return new Date(new Date('1970-01-01T' + time + 'Z').getTime() + (-timezoneOffset * 60000));
    }
  }
}
