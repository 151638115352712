import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Card, CardType} from '../../domain/card';
import {chartExample2, chartOptions, colors, parseOptions} from '../../../../infrastructure/variables/charts';
import * as Chart from 'chart.js';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, OnChanges {

  @Input()
  card: Card = { id: '', name: '', description: '', type: CardType.CHART, subtype: '', position: 0,
    verticalSize: 0, horizontalSize: 0, actions: [] };

  @Input()
  data!: any;

  cardChart: any;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.data) {
      this.draw();
    }
  }

  draw(): void {
    if (this.cardChart) {
      this.cardChart.destroy();
    }

    const cardCanvas = document.getElementById('card-' + this.card.id);
    if (cardCanvas) {
      if (this.card.subtype?.toLowerCase() == 'doughnut' || this.card.subtype?.toLowerCase() == 'pie') {
        for (let i = 0; i < this.data.datasets.length; i++) {
          this.data.datasets[i].backgroundColor = colors.pallete;
        }
      } else {
        for (let i = 0; i < this.data.datasets.length; i++) {
          this.data.datasets[i].backgroundColor = colors.pallete[i % colors.pallete.length];
        }
      }

      parseOptions(Chart, chartOptions());
      // @ts-ignore
      this.cardChart = new Chart(cardCanvas, {
        type: this.card.subtype.toLowerCase(),
        // options: chartExample2.options,
        data: this.data
      });
    }
  }

}
