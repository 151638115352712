<div class="header bg-gradient-danger py-7 py-lg-8">
  <div class="container">
    <div class="header-body text-center mb-6 mt--5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-6">
          <h1 class="text-white">Planos acessíveis para você</h1>
          <p class="text-lead text-light">Você está a um passo de iniciar sua jornada de crescimento!</p>
        </div>
      </div>
    </div>
  </div>
  <div class="separator separator-bottom separator-skew zindex-100">
    <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
    </svg>
  </div>
</div>
<!-- Page content -->
<div class="container mt--8 pb-5">
  <div class="row justify-content-center">
    <div class="col-3">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5">
          <div class="text-center display-4">Lite</div>
          <div class="text-center text-muted mb-4">
            <h4>Inicie a otimização do seu escritório</h4>
          </div>
          <div class="text-center mb-4 display-2">Gratuíto</div>
          <div class="text-center text-muted mb-4"><small>por um ano, após R$ 60,00/mês</small></div>

          <div class="text-center text-muted mb-2">
            <button class="btn btn-primary" routerLink="/">Comece agora</button>
          </div>
        </div>
        <div class="card-body px-4 py-4 bg-primary text-white">
          <p><strong>50 processos<br/>monitorados</strong></p>
          <p>1 usuário</p>
          <p>1GB de espaço na nuvem</p>
          <p>Processos cadastrados ilimitados</p>
        </div>
      </div>
    </div>

    <div class="col-3">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5">
          <div class="text-center display-4">Start</div>
          <div class="text-center text-muted mb-4">
            <h4>Automatize e foque no que você faz melhor</h4>
          </div>
          <div class="text-center mb-4 display-2">R$ 120</div>
          <div class="text-center text-muted mb-4"><small>por mês</small></div>

          <div class="text-center text-muted mb-2">
            <button class="btn btn-primary" routerLink="/">Comece agora</button>
          </div>
        </div>
        <div class="card-body px-4 py-4 bg-primary text-white">
          <p><strong>150 processos<br/>monitorados</strong></p>
          <p>2 usuários</p>
          <p>2GB de espaço na nuvem</p>
          <p>Processos cadastrados ilimitados</p>
        </div>
      </div>
    </div>

    <div class="col-3">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5">
          <div class="text-center display-4">Plus</div>
          <div class="text-center text-muted mb-4">
            <h4>Cresça e trabalhe com inteligência</h4>
          </div>
          <div class="text-center mb-4 display-2">R$ 240</div>
          <div class="text-center text-muted mb-4"><small>por mês</small></div>

          <div class="text-center text-muted mb-2">
            <button class="btn btn-primary" routerLink="/">Comece agora</button>
          </div>
        </div>
        <div class="card-body px-4 py-4 bg-primary text-white">
          <p><strong>500 processos<br/>monitorados</strong></p>
          <p>5 usuários</p>
          <p>6GB de espaço na nuvem</p>
          <p>Processos cadastrados ilimitados</p>
        </div>
      </div>
    </div>

    <div class="col-3">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5">
          <div class="text-center display-4">Enterprise</div>
          <div class="text-center text-muted mb-4">
            <h4>Esteja junto dos melhores e inove</h4>
          </div>
          <div class="text-center mb-4 display-2">R$ 460</div>
          <div class="text-center text-muted mb-4"><small>por mês</small></div>

          <div class="text-center text-muted mb-2">
            <button class="btn btn-primary" routerLink="/">Comece agora</button>
          </div>
        </div>
        <div class="card-body px-4 py-4 bg-primary text-white">
          <p><strong>1000 processos monitorados</strong></p>
          <p>20 usuários</p>
          <p>10GB de espaço na nuvem</p>
          <p>Processos cadastrados ilimitados</p>
        </div>
      </div>
    </div>
  </div>
</div>
