import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/infrastructure/service/api.service';
import { GeneralSearch } from '../domain/general-search';

@Injectable({
  providedIn: 'root'
})
export class GeneralSearchService extends ApiService {

  private serviceUrl = 'generalsearch';

  constructor(http: HttpClient) {
    super(http);

  }

  findByText(name: string): Promise<GeneralSearch[]> {
    return super.get(`${this.serviceUrl}?name=${name}`).toPromise();
  }
}
