<app-core></app-core>
<div
  class="app-layout-container"
  [class.example-is-mobile]="mobileQuery.matches"
>
  <app-sidenav #menu></app-sidenav>

  <mat-sidenav-container
    class="app-layout-sidenav-container bg-white"
    [style.marginTop.px]="0"
  >
<!--    <mat-sidenav-->
<!--      #snav-->
<!--      [mode]="'over'"-->

<!--      [fixedInViewport]="mobileQuery.matches"-->
<!--      [fixedTopGap]="0"-->
<!--    >-->
<!--    </mat-sidenav>-->
    <mat-sidenav
      #sidenav
      mode="over"
      position="end"
      [fixedInViewport]="true"
      [fixedTopGap]="0"
      [fixedBottomGap]="0"
    >
      <router-outlet name="sidenav"></router-outlet>
    </mat-sidenav>
    <mat-sidenav-content id="sidenav-content" (click)="menu.close($event)">
      <div class="main-content m-0">
        <app-navbar
          [sidenav]="sidenav"
          (changeTenantOptionsEvent)="showTenantOptions()"
          [snav]="menu"
        ></app-navbar>

        <div
          class="card p-3 dropdown-menu label-add-menu add_new_contact_fields"
          [hidden]="hiddenTenantOptions"
        >
          <app-select
          [data]="1"
            [options]="tenantOptions"
            name="Select Tenant"
            (changed)="changeTenantId($event)"
          ></app-select>
          <button (click)="hideTenantOptions()">Cancel</button>
        </div>

        <!-- Pages -->
        <router-outlet name="main"></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <div *ngIf="updateService.updateAvailable" class="alert alert-warning app-layout-bottom-alert text-center" role="alert">
    {{ 'core.message.reload.new_version' | translate }} <a href="#" class="alert-link" (click)="reloadPage()">{{ 'core.message.reload.click_here' | translate }}</a> {{ 'core.message.reload.to_reload' | translate }}
  </div>

  <app-loading></app-loading>
</div>
