<div [className]="getFormGroupClass()" [formGroup]="formGroup">
  <label class="form-control-label mb-0" [for]="dbname" *ngIf="name">{{ name }}</label>
  <input
    type="number"
    [formControlName]="dbname"
    [className]="getFormControlClass()"
    [placeholder]="description"
    [value]="data"
    (change)="changeInput($event)"
    [required]="required"
    [pattern]="'^\\d*(\\.\\d{0,' + this.decimal + '})?$'"
    [min]="this.min"
    [max]="this.max"
    [readonly]="readonly"
    [attr.disabled]="disabled ? true : null"
  />
  <!-- [pattern]="'^\d*(\.\d{0,' + this.decimal + '})?$'" -->
</div>

