<div class="svg-item">
  <svg width="100%" height="100%" viewBox="0 0 40 40" class="donut">
    <circle class="donut-hole" cx="20" cy="20" r="15.91549430918954" fill="transparent"></circle>
    <circle class="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="1.5"></circle>
    <!-- TODO: Transformar em um loop para criar mais de um segmento se necessário -->
    <circle [class]="getSegmentClass(0)" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="3.5" attr.stroke-dasharray="{{ percent[0] + ' ' + (100 - percent[0]) }}" stroke-dashoffset="25"></circle>

    <g class="donut-text donut-text-secondary">

      <text y="52%" transform="translate(0, 2)">
        <tspan x="50%" text-anchor="middle" [class]="getTextClass()">{{ getText() }}</tspan>
      </text>
      <text y="68%" transform="translate(0, 2)">
        <tspan x="50%" text-anchor="middle" class="donut-data">{{ description }}</tspan>
      </text>
    </g>
  </svg>
</div>
