import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BaseFieldComponent} from './base-field/base-field.component';
import {AutoCompleteComponent} from './auto-complete/auto-complete.component';
import {ChipsComponent} from './chips/chips.component';
import {InputDateComponent} from './input-date/input-date.component';
import {InputNumericComponent} from './input-numeric/input-numeric.component';
import {InputTextComponent} from './input-text/input-text.component';
import {InputHiddenComponent} from './input-hidden/input-hidden.component';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import {MatChipsModule} from '@angular/material/chips';
import {SelectComponent} from './select/select.component';
import {MkEditorComponent} from './mk-editor/mk-editor.component';
import {LMarkdownEditorModule} from 'ngx-markdown-editor';
import {TextLabelComponent} from './text-label/text-label.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {RouterModule} from '@angular/router';
import {InputFileComponent} from './input-file/input-file.component';
import {SliderComponent} from './slider/slider.component';
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS} from '@angular/material-moment-adapter';
import { CkEditorComponent } from './ck-editor/ck-editor.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {InputTextareaComponent} from './input-textarea/input-textarea.component';
import { InputTimeComponent } from './input-time/input-time.component';
import { CalendarComponent } from './calendar/calendar.component';
import {DayPilotModule} from '@daypilot/daypilot-lite-angular';
import {TranslateModule} from '@ngx-translate/core';
import { NewEventComponent } from './calendar/new-event/new-event.component';
import {MatDialogModule} from '@angular/material/dialog';
import { InputAmountTimeComponent } from './input-amount-time/input-amount-time.component';
import { InputDateTimeComponent } from './input-date-time/input-date-time.component';
import { InputCheckComponent } from './input-check/input-check.component';


@NgModule({
  declarations: [
    BaseFieldComponent,
    AutoCompleteComponent,
    ChipsComponent,
    InputDateComponent,
    InputNumericComponent,
    InputTextComponent,
    InputTextareaComponent,
    InputHiddenComponent,
    SelectComponent,
    MkEditorComponent,
    TextLabelComponent,
    InputFileComponent,
    SliderComponent,
    CkEditorComponent,
    InputTimeComponent,
    CalendarComponent,
    NewEventComponent,
    InputAmountTimeComponent,
    InputDateTimeComponent,
    InputCheckComponent
  ],
  exports: [
    AutoCompleteComponent,
    InputTextComponent,
    InputTextareaComponent,
    InputDateComponent,
    ChipsComponent,
    InputNumericComponent,
    InputHiddenComponent,
    SelectComponent,
    TextLabelComponent,
    MkEditorComponent,
    InputFileComponent,
    SliderComponent,
    CkEditorComponent,
    InputTimeComponent,
    CalendarComponent,
    InputAmountTimeComponent,
    InputDateTimeComponent,
    InputCheckComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatChipsModule,
    LMarkdownEditorModule,
    MatProgressBarModule,
    RouterModule,
    CKEditorModule,
    DayPilotModule,
    TranslateModule,
    MatDialogModule
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true, strict: true} },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
  ]
})
export class AppFormsModule {
}
