<div class="card card-stats mb-4 mb-xl-0" [ngClass]="darkCard ? 'bg-default text-white' : ''" *ngIf="card?.type === cardType.INDICATOR">
  <div class="card-body">
    <app-indicator [cardStyle]="cardStyle" [card]="card" [data]="response"></app-indicator>
  </div>
</div>
<div class="card shadow" *ngIf="card?.type !== cardType.INDICATOR">
  <div class="card-header bg-transparent" *ngIf="showHeader">
    <div class="row align-items-center">
      <div class="col">
        <h2 class="mb-1">{{card?.name}}</h2>
        <h6 class="text-uppercase text-muted ls-1 mb-0">{{card?.description}}</h6>
      </div>
      <div class="col text-right">
        <a href="#!" class="btn btn-sm btn-primary" *ngFor="let action of card?.actions">{{action.name}}</a>
      </div>
    </div>
  </div>
  <div class="card-body">
    <span [ngSwitch]="card?.type">
      <app-chart *ngSwitchCase="cardType.CHART" [card]="card" [data]="response"></app-chart>
      <app-table *ngSwitchCase="cardType.TABLE" [card]="card" [data]="response"></app-table>
      <div *ngSwitchCase="cardType.HTML" [innerHTML]="response?.html | safeHtml"></div>
    </span>
  </div>
</div>

