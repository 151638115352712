import {Injectable} from '@angular/core';
import {ApiService} from '../../../infrastructure/service/api.service';
import {HttpClient} from '@angular/common/http';
import {Card} from '../domain/card';

@Injectable({
  providedIn: 'root'
})
export class CardService extends ApiService {

  private serviceUrl = 'cards';

  public findById(id: string): Promise<Card> {
    return this.get(`${this.serviceUrl}/${id}`).toPromise();
  }

  public draw(id: string, requestBody: any): Promise<any> {
    return super.post(`${this.serviceUrl}/${id}/draw`, requestBody).toPromise();
  }

}
