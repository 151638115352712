import {NgModule} from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import {AppLayoutComponent} from './app-layout.component';
import {AppFormsModule} from './forms/app-forms.module';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NavbarComponent} from './navbar/navbar.component';
import {SidenavComponent} from './sidenav/sidenav.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CoreModule} from '../../modules/core/core.module';
import {SwitchTenantComponent} from './navbar/switch-tenant/switch-tenant.component';
import {AvatarComponent} from './others/avatar/avatar.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {LoadingComponent} from './loading/loading.component';
import {DoughnutComponent} from './others/doughnut/doughnut.component';
import {GaugeComponent} from './others/gauge/gauge.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        CoreModule,
        AppFormsModule,
        TranslateModule,
        MatProgressSpinnerModule,
        MatDialogModule
    ],
    declarations: [
        AppLayoutComponent,
        NavbarComponent,
        SidenavComponent,
        SwitchTenantComponent,
        AvatarComponent,
        LoadingComponent,
        DoughnutComponent,
        GaugeComponent
    ],
  exports: [
    AppLayoutComponent,
    NavbarComponent,
    SidenavComponent,
    AvatarComponent,
    DoughnutComponent,
    GaugeComponent
  ],
  providers: [DecimalPipe]
})
export class ComponentsModule { }
