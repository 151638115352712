<header class="header">
  <h1>Pure css gauge</h1>
</header>

<section class="content">
  <div class="box gauge--1">
    <div class="mask">
      <div class="semi-circle"></div>
      <div class="semi-circle--mask"></div>
    </div>
  </div>

  <div class="box gauge--2">
    <div class="mask">
      <div class="semi-circle"></div>
      <div class="semi-circle--mask"></div>
    </div>
  </div>

  <div class="box gauge--3">
    <div class="mask">
      <div class="semi-circle"></div>
      <div class="semi-circle--mask"></div>
    </div>
  </div>
</section>
