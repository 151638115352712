import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import {WebSocketHandler} from './web-socket-handler';
import {HttpHeaders} from '@angular/common/http';
import {UserService} from '../auth/user.service';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  webSocketEndPoint = `${environment.apiUrl}/ws`;

  // wsInstances: WebSocketServiceInstance[] = [];

  constructor(private userService: UserService) {
  }

  connect(topic: string, handler: WebSocketHandler): WebSocketServiceInstance {
    const tenantId: string = this.userService.loadLoggedUserTenant()?.tenant?.id || '-1';
    const wsInstance: WebSocketServiceInstance = new WebSocketServiceInstance(this.webSocketEndPoint, topic, handler, tenantId);
    wsInstance.connect();
    return wsInstance;
  }

}

export class WebSocketServiceInstance {

  stompClient: any;

  private webSocketEndPoint: string;
  private topic: string;
  private handler: WebSocketHandler;
  private tenantId: string;

  constructor(webSocketEndPoint: string, topic: string, handler: WebSocketHandler, tenantId: string) {
    this.webSocketEndPoint = webSocketEndPoint;
    this.topic = topic;
    this.handler = handler;
    this.tenantId = tenantId;
  }

  connect(): void {
    // console.log('Initialize WebSocket Connection');
    const ws = new SockJS(`${this.webSocketEndPoint}?access_token=${localStorage.getItem('access_token')}`);
    this.stompClient = Stomp.over(ws);

    const headers: any = this.prepareHeaders();
    const _this = this;
    const topicUri = this.topic.charAt(0) === '/' ? `/topic/${this.tenantId}${this.topic}` : `/topic/${this.tenantId}/${this.topic}`;
    _this.stompClient.connect(headers, (frame: any) => {
      _this.stompClient.subscribe(topicUri, (sdkEvent: any) => _this.onMessageReceived(sdkEvent), headers);
      _this.stompClient.reconnect_delay = 2000;
    }, this.errorCallBack);
  }

  private prepareHeaders(): any {
    return {Authorization: 'Bearer ' + localStorage.getItem('access_token')};

    // let headers: any = new HttpHeaders();
    // headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    // headers = headers.append('Accept-Language', localStorage.getItem('locale') || 'en-US');
    // const userTenant = sessionStorage.getItem('user-tenant') || localStorage.getItem('user-tenant');
    // if (userTenant) {
    //   headers = headers.append('X-TenantID', JSON.parse(userTenant).tenant.id);
    // }
    // return headers;
  }

  disconnect(): void {
    if (this.stompClient !== null) {
      this.stompClient.disconnect();
    }
    console.log('Disconnected');
  }

  // on error, schedule a reconnection attempt
  errorCallBack(error: any): void {
    console.log('errorCallBack -> ' + error);
    const _this = this;
    // setTimeout(() => {
    //   _this.connect();
    // }, 5000);
  }

  send(message: string): void {
    console.log('calling logout api via web socket');
    this.stompClient.send('/app/hello', this.prepareHeaders(), JSON.stringify(message));
  }

  onMessageReceived(message: any): void {
    console.log('Message Recieved from Server :: ' + message);
    this.handler.handleMessage(message.body);
  }
}
