import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // let headers: HttpHeaders = new HttpHeaders();
    // headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    //
    // const userTenant = sessionStorage.getItem('user-tenant') || localStorage.getItem('user-tenant');
    // if (userTenant) {
    //   headers = headers.append('X-TenantID', JSON.parse(userTenant).tenant.id);
    // }
    //
    // const modifiedReq = req.clone({
    //   headers: headers,
    // });

    return next.handle(req);
  }

}
