import { Routes } from '@angular/router';

export const AppLayoutRoutes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../modules/dashboard/dashboard.module').then(m => m.DashboardModule),
  },

  {
    path: 'tasks',
    children: [
      {
        path: '',
        loadChildren: () => import('../modules/task/task.module').then(m => m.TaskModule),
      },
      {
        path: ':id',
        loadChildren: () => import('../modules/task/task.module').then(m => m.TaskModule),
      }
    ]
  },
  {
    path: 'projects',
    children: [
      {
        path: '',
        loadChildren: () => import('../modules/project/project.module').then(m => m.ProjectModule),
      },
      {
        path: 'legal-batch-import',
        loadChildren: () => import('../modules/project/project.module').then(m => m.ProjectModule),
      },
      {
        path: ':id',
        loadChildren: () => import('../modules/project/project.module').then(m => m.ProjectModule),
      }
    ]
  },
  {
    path: 'finance',
    children: [
      {
        path: '',
        loadChildren: () => import('../modules/finance/finance.module').then(m => m.FinanceModule),
      },
      {
        path: 'invoice',
        loadChildren: () => import('../modules/invoice/invoice.module').then(m => m.InvoiceModule)
      },
      {
        path: ':id',
        loadChildren: () => import('../modules/finance/finance.module').then(m => m.FinanceModule),
      }
    ]
  },
  {
    path: 'doc-generator',
    children: [
      {
        path: '',
        loadChildren: () => import('../modules/document-generator/document-generator.module').then(m => m.DocumentGeneratorModule),
      }
    ]
  },
  {
    path: 'customers',
    children: [
      {
        path: '',
        loadChildren: () => import('../modules/customer/customer.module').then(m => m.CustomerModule),
      },
      {
        path: ':id',
        loadChildren: () => import('../modules/customer/customer.module').then(m => m.CustomerModule),
      }
    ]
  },
  {
    path: 'settings',
    loadChildren: () => import('../modules/user-tenant-settings/user-tenant-settings.module').then(m => m.UserTenantSettingsModule),
  },

  {
    path: 'invite/:id',
    loadChildren: () => import('../modules/user-tenant-settings/user-tenant-settings.module').then(m => m.UserTenantSettingsModule),

  },

  {
    path: 'user_settings',
    loadChildren: () => import('../modules/user-settings/user-settings.module').then(m => m.UserSettingsModule),
  },


  {
    path: 'windows/:path',
    children: [
      {
        path: '',
        loadChildren: () => import('../modules/window/window.module').then(m => m.WindowModule),
      },
      {
        path: ':detail/:id',
        loadChildren: () => import('../modules/window/window.module').then(m => m.WindowModule),
      }
    ]
  },
  {
    path: 'crud/:path',
    children: [
      {
        path: '',
        loadChildren: () => import('../modules/crud/crud.module').then(m => m.CrudModule),
      },
      {
        path: ':id',
        loadChildren: () => import('../modules/crud/crud.module').then(m => m.CrudModule),
      }
    ]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  }
];
