export interface AppNotification {
  id: string;
  title: string;
  description: string;
  link: string;
  read: boolean;
  type: AppNotificationType;
  created: Date;
  updated: Date;
}

export enum AppNotificationType {
  CALENDAR = 'CALENDAR',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFORMATION = 'INFORMATION'
}
