import {Injectable, EventEmitter} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {Router} from '@angular/router';
import { Task } from 'src/app/modules/task/domain/task';
import {MatDrawerToggleResult} from '@angular/material/sidenav/drawer';
import {Subscription} from 'rxjs';

@Injectable()
export class SidenavService {

  private sidenav!: MatSidenav;

  private previousUrl!: string | undefined;

  public params: any;

  public changed: EventEmitter<any> = new EventEmitter();
  public removed: EventEmitter<string> = new EventEmitter();

  constructor(private router: Router) { }

  public setSidenav(sidenav: MatSidenav): void {
    if (sidenav) {
      this.sidenav = sidenav;
    }
  }

  public open(params?: any, previousUrl?: string): any {
    if (this.sidenav) {
      this.params = params;
      this.previousUrl = previousUrl || this.router.url;
      this.sidenav.closedStart.subscribe(() => {
        setTimeout(() => {
          this.changed.observers.forEach((o: any) => o.unsubscribe());
          this.removed.observers.forEach((o: any) => o.unsubscribe());
          if (this.previousUrl) {
            this.router.navigateByUrl(this.previousUrl);
            this.previousUrl = undefined;
          }
        }, 1);
      });
      return this.sidenav.open();
    }
  }

  public close(): Promise<MatDrawerToggleResult> {
    this.params = undefined;
    return this.sidenav.close();
  }

  public closeIgnorePreviousUrl(): Promise<MatDrawerToggleResult> {
    this.params = undefined;
    this.previousUrl = undefined;
    return this.sidenav.close();
  }

  public toggle(): void {
    this.sidenav.toggle();
  }

  public getSidenavComponent(): MatSidenav {
    return this.sidenav;
  }

}
