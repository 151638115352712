<div class="container m-5">
  <div class="card card-stats mb-4 mb-xl-0">
    <div class="card-body">

      <!-- Start card layout here -->

      <div class="row">
        <div class="col border-right">
          <div class="mb-4 mb-lg-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Orders</h5>
                  <span class="h2 font-weight-bold mb-0">350,897</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-default text-white rounded-circle shadow">
                    <i class="fa-solid fa-users"></i>
                  </div>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                <span class="text-nowrap">Last month</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div style="width: 18rem;">
            <div class="mb-4 mb-lg-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">Returns</h5>
                    <span class="h2 font-weight-bold mb-0">350,897</span>
                  </div>
                  <div class="col-auto">
                    <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
                      <i class="fas fa-undo-alt"></i>
                    </div>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-muted text-sm">
                  <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                  <span class="text-nowrap">Last month</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg-12 text-center">
          <button class="btn btn-sm btn-primary">Reset / Reload</button>
          <button class="btn btn-sm btn-primary">Refresh Cards</button>
        </div>
      </div>

      <!-- End card layout here -->

    </div>
  </div>

  <div class="card card-stats mb-4 mb-xl-0">
    <div class="card-body">

      <!-- Start card layout here -->

      <div class="row">
        <div class="col text-center">
          <div class="mb-4 mb-lg-0">
            <div class="card-body">
              <!-- primeiro esta configurado no sistema de screenshot -->
              <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="icon icon-sm icon-shape bg-default text-white rounded-circle shadow" style="z-index: 3">
                  <i class="fa-solid fa-comments"></i>
                </div>
                <div class="bg-default" style="margin-top: -1.05rem; height: 2px; width: 350px; z-index: 2"></div>
                <h4 class="text-default" style="margin-top: 0.75rem">assuntos</h4>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="icon icon-sm icon-shape bg-default text-white rounded-circle shadow" style="z-index: 3">
                  <i class="fa-solid fa-user-tie"></i>
                </div>
                <div class="bg-default" style="margin-top: -1.05rem; height: 2px; width: 350px; z-index: 2"></div>
                <h4 class="text-default" style="margin-top: 0.75rem">advogados</h4>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="icon icon-sm icon-shape bg-default text-white rounded-circle shadow" style="z-index: 3">
                  <i class="fa-solid fa-file-invoice"></i>
                </div>
                <div class="bg-default" style="margin-top: -1.05rem; height: 2px; width: 350px; z-index: 2"></div>
                <h4 class="text-default" style="margin-top: 0.75rem">ficha</h4>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="icon icon-sm icon-shape bg-default text-white rounded-circle shadow" style="z-index: 3">
                  <i class="fa-solid fa-users"></i>
                </div>
                <div class="bg-default" style="margin-top: -1.05rem; height: 2px; width: 350px; z-index: 2"></div>
                <h4 class="text-default" style="margin-top: 0.75rem">partes</h4>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="icon icon-sm icon-shape bg-default text-white rounded-circle shadow" style="z-index: 3">
                  <i class="fa-solid fa-scale-balanced"></i>
                </div>
                <div class="bg-default" style="margin-top: -1.05rem; height: 2px; width: 350px; z-index: 2"></div>
                <h4 class="text-default" style="margin-top: 0.75rem">2º instância</h4>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="icon icon-sm icon-shape bg-default text-white rounded-circle shadow" style="z-index: 3">
                  <i class="fa-solid fa-landmark"></i>
                </div>
                <div class="bg-default" style="margin-top: -1.05rem; height: 2px; width: 350px; z-index: 2"></div>
                <h4 class="text-default" style="margin-top: 0.75rem">3º instância</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col text-center">
          <div style="height: 10em; width: 10em">
            <app-doughnut [percent]="[37]"></app-doughnut>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col text-center">
          <app-gauge></app-gauge>
        </div>
      </div>
      <!-- End card layout here -->
    </div>
  </div>
</div>


