<div class="table-responsive">
  <table class="table align-items-center table-flush">
    <thead class="thead-light">
      <tr>
        <th scope="col" *ngFor="let header of data?.headers">{{header}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of data?.data">
        <td *ngFor="let header of data?.headers" [innerHTML]="row[header] | safeHtml"></td>
      </tr>
    </tbody>
  </table>
</div>
