import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.scss']
})
export class GaugeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  // TODO: How change percent
  // setInterval(function() {
  //   var newVal = Math.floor((Math.random() * 179) + 1);
  //
  //   $('.gauge--3 .semi-circle--mask').attr({
  //     style: '-webkit-transform: rotate(' + newVal + 'deg);' +
  //       '-moz-transform: rotate(' + newVal + 'deg);' +
  //       'transform: rotate(' + newVal + 'deg);'
  //   });
  // }, 1000);

}
