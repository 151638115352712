import {ChangeDetectorRef, Component, HostListener, Injectable, OnInit} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {AutoSaveService} from './modules/auto-save/service/auto-save.service';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import { UpdateApplicationService } from './update-application.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;

  title = 'Arquivo X';
  // TODO: habilitar worker para tentar salvar
  //  registros que ficaram presos devido a erros de conexão
  // storageWorker = interval(5000);

  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    this.utilitiesService.documentClickedTarget.next(event.target)
  }

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
              private utilitiesService: UtilitiesService,
              private translate: TranslateService,
              public updateService: UpdateApplicationService, private route: ActivatedRoute) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
    // this.storageWorker.subscribe(n => {
    //   autoSaveService.syncronize();
    // });

    translate.addLangs(['en-US', 'pt-BR']);
    const browserLang = translate.getBrowserLang();
    if (browserLang) {
      translate.use(browserLang.match(/en-US|pt-BR/) ? browserLang : 'en-US');
    }
  }

  public showCommunicationError(): boolean {
    return !this.utilitiesService.failMessageShow;
  }

  ngOnInit(): void {
    this.updateService.checkForUpdates();
  }

}

@Injectable({ providedIn: 'root' })
export class UtilitiesService {
  failMessageShow = false;
  documentClickedTarget: Subject<HTMLElement> = new Subject<HTMLElement>();
}
