import {Action} from '../../action/domain/action';

export enum CardType {
  CHART = 'CHART',
  TABLE = 'TABLE',
  HTML = 'HTML',
  INDICATOR = 'INDICATOR'
}

export interface Card {
  id: string;
  name: string;
  description: string;

  type: CardType;
  subtype: string;
  icon?: string;
  iconColor?: string;

  displayTitle?: boolean;

  position: number;
  verticalSize: number;
  horizontalSize: number;

  actions: Action[];

  breakline?: boolean;
}
