import {Component, HostListener, Input, QueryList} from '@angular/core';
import {ActionRequestParams} from '../action/domain/action-request';
import {ModalComponent} from '../../app-layout/components/window-generator/components/modal/modal.component';
import {SnackbarService} from '../../infrastructure/service/snackbar.service';
import {ActionService} from '../action/service/action.service';
import {MatDialog} from '@angular/material/dialog';
import {CardComponent} from '../card/components/card.component';
import {Router} from '@angular/router';
import {MatSnackBarRef} from '@angular/material/snack-bar/snack-bar-ref';
import {TextOnlySnackBar} from '@angular/material/snack-bar/simple-snack-bar';
import {CardType} from '../card/domain/card';
import {SidenavService} from '../../app-layout/service/sidenav.service';
import {CoreService} from '../../app-layout/service/core.service';
import { SnackBarContentComponent } from 'src/app/infrastructure/service/snack-bar-content/snack-bar-content.component';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss']
})
export class CoreComponent{

  constructor(private snackBar: SnackbarService,
              private actionService: ActionService,
              private sidenav: SidenavService,
              private dialog: MatDialog,
              private router: Router,
              private coreService: CoreService) { }

  @Input()
  public cardComponents!: QueryList<CardComponent>;

  @HostListener('window:onExecuteAction', ['$event.detail.id', '$event.detail.recordId', '$event.detail.params', '$event.detail.onSuccess', '$event.detail.onError'])
  onExecuteAction(actionId: string, recordId?: string,  params?: any, onSuccess?: any, onError?: any): void {
    const requestParams: ActionRequestParams[] = [];

    if (params) {
      Object.keys(params).forEach((key) => {
        const requestParam: ActionRequestParams = {name: key, value: params[key]};
        requestParams.push(requestParam);
      });
    }

    const request: any = {
      recordId: [recordId],
      params: requestParams
    };

    this.actionService.executeAction(actionId, request).then((r) => {
      if (r.js) {
        // tslint:disable-next-line:no-eval
        eval(r.js);
      }

      if (r.message) {
        this.snackBar.info(r.message);
      }
      if (onSuccess) {
        onSuccess(r);
      }
    }).catch((r) => {
      this.snackBar.error(r.error || 'Error:' + r.message);
      if (onError) {
        onError(r);
      }
    });
  }

  @HostListener('window:onOpenModal', ['$event.detail.path', '$event.detail.id', '$event.detail.params', '$event.detail.onClose'])
  onOpenModal(path: string, id?: string, params?: any, onClose?: any): void {
    const data: any = {
      id: id,
      windowPath: path,
      path: path,
      params: params
    };

    const dialogRef = this.dialog.open(ModalComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(onClose);
  }

  @HostListener('window:onRefreshCard', ['$event.detail.id', '$event.detail.params'])
  onRefreshCard(id: string, params?: any): void {
    if (id && this.coreService.cards) {
      this.coreService.cards.find(c => c?.card.id === id)?.draw(params);
    }
  }

  @HostListener('window:onSnackBar', ['$event.detail.type', '$event.detail.message', '$event.detail.onDismiss'])
  onSnackBar(type: string, message: string, onDismiss?: any): void {
    let snackBar: MatSnackBarRef<SnackBarContentComponent>;
    if (type === 'error') {
      snackBar = this.snackBar.error(message);
    } else if (type === 'warn') {
      snackBar = this.snackBar.warning(message);
    } else if (type === 'success') {
      snackBar = this.snackBar.success(message);
    } else {
      snackBar = this.snackBar.info(message);
    }

    if (onDismiss) {
      snackBar.afterDismissed().subscribe(r => onDismiss(r));
    }
  }

  @HostListener('window:onOpenInSideNav', ['$event.detail.url', '$event.detail.windowPath'])
  onOpenInSideNav(url: string, windowPath?: string): void {
    this.sidenav.open(windowPath);
    this.router.navigateByUrl(url);
  }
}
