import { Injectable } from '@angular/core';
import {MatSnackBarRef} from '@angular/material/snack-bar/snack-bar-ref';
import {TextOnlySnackBar} from '@angular/material/snack-bar/simple-snack-bar';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import { SnackBarContentComponent } from './snack-bar-content/snack-bar-content.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private service: MatSnackBar,
    private router: Router) { }

  /* public success(message: string, options?: any): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackbar(message, options, 'alert-success');
  }

  public info(message: string, options?: any): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackbar(message, options, 'alert-info');
  }

  public warning(message: string, options?: any): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackbar(message, options, 'alert-warning', 5000);
  }

  public error(message: string, options?: any): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackbar(message, options, 'alert-danger', 5000);
  }

  public snackbar(message: string, options?: any, snackClass?: string, time?: number): MatSnackBarRef<TextOnlySnackBar> {
    const snackBarRef = this.service.open(message, options?.action || (options?.redirectUrl ? 'Cancel' : undefined),  {
      duration: time || 2000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: ['alert', snackClass || 'alert-default']
    });

    if (options?.redirectUrl) {
      let redirect = true;
      snackBarRef.afterDismissed().subscribe(() => {
        if (redirect) {
          this.router.navigate([options?.redirectUrl]);
        }
      });

      snackBarRef.onAction().subscribe(() => {
        redirect = false;
      });
    }
    return snackBarRef;
  } */

  public snackbar(htmlContent: string, options?: any, snackClass?: string, time?: number): MatSnackBarRef<SnackBarContentComponent> {
    const snackBarRef =  this.service.openFromComponent(SnackBarContentComponent, {
      duration: time || 2000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: ['alert', snackClass || 'alert-default'],
      data: { htmlContent }
    });

    if (options?.redirectUrl) {
      let redirect = true;
      snackBarRef.afterDismissed().subscribe(() => {
        if (redirect) {
          this.router.navigate([options?.redirectUrl]);
        }
      });

      snackBarRef.onAction().subscribe(() => {
        redirect = false;
      });
    }
    return snackBarRef;
  }

  public success(message: string, options?: any): MatSnackBarRef<SnackBarContentComponent> {
    return this.snackbar(message, options, 'alert-success');
  }

  public info(message: string, options?: any): MatSnackBarRef<SnackBarContentComponent> {
    return this.snackbar(message, options, 'alert-info');
  }

  public warning(message: string, options?: any): MatSnackBarRef<SnackBarContentComponent> {
    return this.snackbar(message, options, 'alert-warning', 5000);
  }

  public error(message: string, options?: any): MatSnackBarRef<SnackBarContentComponent> {
    return this.snackbar(message, options, 'alert-danger', 5000);
  }

  /* openSnackBarHTML(htmlContent: string, options?: any, snackClass?: string, time?: number) {
    const snackBarRef =  this.service.openFromComponent(SnackBarContentComponent, {
      duration: time || 2000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: ['alert', snackClass || 'alert-default'],
      data: { htmlContent }
    });
  } */
}
