export enum CrudColumnType {
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  LABEL = 'LABEL',
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  CHECKBOX = 'CHECKBOX',
  TABLE = 'TABLE',
  LIST = 'LIST',
  TEXTAREA = 'TEXTAREA'
}
