<div [className]="getFormGroupClass()" [formGroup]="formGroup">
  <label class="form-control-label mb-0" [for]="dbname" *ngIf="name" >{{name}}</label>
  <input
    #inputElement
    type="text"
    [formControlName]="dbname"
    [className]="getFormControlClass()"
    [placeholder]="description"
    [value]="data"
    (change)="changeInput($event)"
    [required]="required"
    [readonly]="readonly"
    [disabled]="disabled"
    [attr.disabled]="disabled ? true : null"
  />
</div>


