import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Filter} from '../../modules/core/domain/filter';
import {json} from 'express';
import {QueueAutoSaveFields} from '../../modules/auto-save/domain/queue-auto-save';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiUrl: string = environment.apiUrl;

  protected http: HttpClient;

  protected locale: string;

  constructor(http: HttpClient) {
    this.http = http;
    this.locale = localStorage.getItem('locale') || 'en-US';
  }

  protected getByFilters(service: string, filter: Filter[] | Filter): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    if (Array.isArray(filter)) {
      filter.forEach(f => httpParams = httpParams.append(f.key, f.valueAsString));
    } else {
      httpParams = httpParams.append(filter.key, filter.valueAsString);
    }
    return this.get(service, httpParams);
  }

  protected get(service: string, params?: HttpParams): Observable<any> {
    return this.http.get(this.prepareUrl(service), this.prepareOptions(params));
  }

  protected getAs(service: string, responseType: string, params?: HttpParams): Observable<any> {
    return this.http.get(this.prepareUrl(service), this.prepareOptions(params, false, responseType));
  }

  protected post(service: string, body?: any, params?: HttpParams, notifyProgress?: boolean): Observable<any> {
    return this.http.post(this.prepareUrl(service), body, this.prepareOptions(params, notifyProgress));
  }

  protected patch(service: string, body?: any, params?: HttpParams): Observable<any> {
    return this.http.patch(this.prepareUrl(service), body, this.prepareOptions(params));
  }

  protected delete(service: string, params?: HttpParams): Observable<any> {
    return this.http.delete(this.prepareUrl(service), this.prepareOptions(params));
  }

  private prepareOptions(params?: HttpParams, notifyProgress?: boolean, responseType?: string): any {

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    headers = headers.append('Accept-Language', this.locale);

    const userTenant = sessionStorage.getItem('user-tenant') || localStorage.getItem('user-tenant');
    if (userTenant) {
      headers = headers.append('X-TenantID', JSON.parse(userTenant).tenant.id);
    }

    const options = {
      headers: new HttpHeaders(),
      params: new HttpParams(),
      responseType: responseType || 'json',
      reportProgress: !!notifyProgress,
      observe: notifyProgress ? 'events' : undefined
    };

    options.headers = headers;
    if (params) {
      options.params = params;
    }

    return options;
  }

  private prepareUrl(service: string): string {
    return this.apiUrl + '/' + service;
  }

  protected toGenericDTO(object: any): any[] {
    const genericDTO: any[] = [];
    Object.keys(object).forEach(k => {
      let value: string;
      switch (object[k]?.constructor) {
        case String:
          value = object[k];
          break;
        case Date:
          value = object[k].toISOString();
          break;
        default:
          value = JSON.stringify(object[k]);
      }
      genericDTO.push({name: k, value: value});
    });

    return genericDTO;
  }

  private filtersToParam(filters: Filter[]): any[] {
    const genericDTO: any[] = [];
    filters?.forEach(f => genericDTO.push({name: f.key, value: JSON.stringify(f.value)}));
    return genericDTO;
  }

  private filterToParam(filter: Filter): any {
    return {name: filter.key, value: JSON.stringify(filter.value)};
  }
}
