import {Component, Inject, OnInit} from '@angular/core';
import {UserService} from '../../../../infrastructure/auth/user.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserTenant} from '../../../../infrastructure/auth/user-tenant';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-switch-tenant',
  templateUrl: './switch-tenant.component.html',
  styleUrls: ['./switch-tenant.component.scss']
})
export class SwitchTenantComponent implements OnInit {

  userTenants!: UserTenant[];

  showNewTenantInput = false;

  formGroupNewTenant: FormGroup = new FormGroup({});

  creating = false;

  constructor(public dialogRef: MatDialogRef<SwitchTenantComponent>,
              private service: UserService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private formBuilder: FormBuilder) {
                this.formGroupNewTenant = this.formBuilder.group({
                  tenant_name: ['']
                });
  }

  ngOnInit(): void {
    this.userTenants = this.data;
  }

  switchTenant(userTenant: UserTenant): void {
    this.service.switchTenant(userTenant);
    window.location.reload();
  }

  createNewTenant(): void {
    this.creating = true;
    const newTenantName = this.formGroupNewTenant.controls['tenant_name'].value;
    this.service.createNewTenant(newTenantName)
      .then(ut => this.switchTenant(ut));
  }
}
