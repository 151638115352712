import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MenuService} from '../../service/menu.service';
import {WindowType} from '../../../modules/crud/domain/window-type';
import {AuthService} from '../../../infrastructure/auth/auth.service';
import {SidenavService} from '../../service/sidenav.service';
import {UserService} from 'src/app/infrastructure/auth/user.service';
import {UserTenant} from 'src/app/infrastructure/auth/user-tenant';
import {User} from 'src/app/infrastructure/auth/user';
import {MatDialog} from '@angular/material/dialog';
import {SwitchTenantComponent} from '../navbar/switch-tenant/switch-tenant.component';
import {Tenant} from '../../../infrastructure/auth/tenant';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = [
  {path: '/dashboard', title: 'Dashboard', icon: 'ni-tv-2 text-primary', class: ''},
  // { path: '/icons', title: 'Icons',  icon:'ni-planet text-blue', class: '' },
  // { path: '/maps', title: 'Maps',  icon:'ni-pin-3 text-orange', class: '' },
  // { path: '/user-profile', title: 'User profile',  icon:'ni-single-02 text-yellow', class: '' },
  {path: '/windows/example-window/view', title: 'Example window', icon: 'ni-chart-bar-32 text-blue', class: ''},
  {path: '/windows/table-and-columns', title: 'Table and Columns', icon: 'ni-bullet-list-67 text-red', class: ''},
  {path: '/windows/windows-tabs-and-fields', title: 'Windows, tabs and fields', icon: 'ni-bullet-list-67 text-red', class: ''},
  {path: '/login', title: 'Login', icon: 'ni-key-25 text-info', class: ''},
  // { path: '/register', title: 'Register',  icon:'ni-circle-08 text-pink', class: '' }
];

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @ViewChild('sidemenu')
  sidemenu!: ElementRef;

  windowType: typeof WindowType = WindowType;

  public menuItems!: any[];
  public isCollapsed = true;

  isLimited = false;
  isAdmin = false;

  userTenants!: UserTenant[];

  constructor(private router: Router,
              private service: MenuService,
              private authService: AuthService,
              private sidenavService: SidenavService,
              public userService: UserService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {

    this.authService.checkCredentials().then(auth => {
      if (auth) {
        this.service.getMenu().then(m => {
          const routes: RouteInfo[] = [];
          m.forEach(menu => {
            let windowPath = '/';
            switch (menu.type) {
              case this.windowType.ANGULAR:
                windowPath += menu.path;
                break;
              case this.windowType.FREE:
                windowPath += 'windows/' + menu.path;
                break;
              case this.windowType.CRUD:
                windowPath += 'crud/' + menu.path;
                break;
            }
            routes.push({path: windowPath, title: menu.name, icon: menu.icon, class: ''});
          });
          this.menuItems = routes;
        });

        this.userService.getUserTenants().then(ut => {
          this.userTenants = ut;
        });

      }
    });

    const userTenant = this.userService.loadLoggedUserTenant();

    if (userTenant) {
      this.isLimited = userTenant.limited;
      this.isAdmin = userTenant.user?.admin || false;
    }
  }

  close(event?: any): void {
    if (!event) {
      this.sidenavService.closeIgnorePreviousUrl();
    }

    if (this.isCollapsed || event?.target?.classList?.contains('menu-toggle')) {
      return;
    }

    this.isCollapsed = true;
    this.openOrCloseSidenav();
  }

  toggle(): void {
    this.isCollapsed = !this.isCollapsed;

    this.openOrCloseSidenav();
  }

  private openOrCloseSidenav(): void {
    if (this.isCollapsed) {
      this.sidemenu.nativeElement.classList.remove('opened');
    } else {
      this.sidemenu.nativeElement.classList.add('opened');
    }
  }

  showTenantOptions(): void {
    this.close();
    const dialogRef = this.dialog.open(SwitchTenantComponent, {
      data: this.userTenants,
      // position: position,
      // height: '100vh'
    });
  }
}
