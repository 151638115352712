import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BaseFieldComponent, FieldType } from '../base-field/base-field.component';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent extends BaseFieldComponent {
  @ViewChild('inputElement')
  public inputElement!: ElementRef;

  constructor() {
    super();
    this.fieldType = FieldType.TEXT;
  }
  ngOnInit(): void {
  this.formGroup.addControl(this.dbname, this.formControl);
}
}
