import {Tenant} from './tenant';
import {User} from './user';

export interface UserTenant {
  id: string;
  user: User;
  tenant: Tenant;
  role: UserTenantRole;
  mailSettings?: MailSettings;
  limited: boolean;
}

export enum UserTenantRole {
  PRINCIPAL = 'PRINCIPAL',
  WRITE = 'WRITE',
  READ = 'READ'
}

export enum MailSettings {
  ALL = 'ALL',
  CONTRIBUTOR = 'CONTRIBUTOR',
  OWNER = 'OWNER',
  NONE = 'NONE'
}
