import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import * as DOMPurify from 'dompurify';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {}

  transform(value: string, ...args: unknown[]): unknown {
    // const sanitizedContent = DOMPurify.sanitize(value);
    // return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

}
