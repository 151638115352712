import { Component, EventEmitter, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { BaseFieldComponent, FieldType, FormField } from '../base-field/base-field.component';

@Component({
  selector: 'app-text-label',
  templateUrl: './text-label.component.html',
  styleUrls: ['./text-label.component.scss']
})
export class TextLabelComponent extends BaseFieldComponent {

}
